import React from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';

import AddIcon from '@mui/icons-material/Add';
import { Box, Stack, TextField, Typography, IconButton, Autocomplete } from '@mui/material';

const OspSelectAutocomplete = ({
  name,
  control,
  options,
  defaultValue,
  label,
  required,
  handleTextFieldChange,
  multiple,
  disabled,
  size,
  onChange,
  handleCreateModal,
  buttonText
}) => {
  const getDefaultValue = () => {
    if (multiple) {
      return Array.isArray(defaultValue) ? defaultValue : [];
    }
    return defaultValue || null;
  };

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={getDefaultValue()}
      render={({ field }) => {
        const getValue = () => {
          if (multiple) {
            return Array.isArray(field.value) ? field.value : [];
          }
          return field.value || null;
        };

        return (
          <Autocomplete
            {...field}
            multiple={multiple || false}
            id={`${name}-select-demo`}
            sx={{ width: '100%' }}
            options={options || []}
            className="auto-tag-input-2"
            autoHighlight
            size="medium"
            disabled={disabled || false}
            getOptionLabel={(option) => option.label || ''}
            isOptionEqualToValue={(option, value) => option.value === value.value}
            onChange={(event, newValue) => {
              field.onChange(newValue);
              if (onChange) onChange(event, newValue);
            }}
            onInputChange={(event, newInputValue) => {
              if (handleTextFieldChange) handleTextFieldChange(newInputValue);
            }}
            value={getValue()}
            renderOption={(props, option) => (
              <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                {option.isButton ? (
                  <Stack
                    width="100%"
                    height="40px"
                    direction="column"
                    justifyContent="center"
                    px={1}
                    sx={{ backgroundColor: '#F8EBF6', borderRadius: '8px' }}
                    onClick={handleCreateModal}
                  >
                    <Stack direction="row" spacing={1} alignItems="center">
                      <IconButton
                        size="small"
                        sx={{ backgroundColor: 'white', ':hover': { backgroundColor: 'white' } }}
                      >
                        <AddIcon fontSize="small" sx={{ color: '#933E88' }} />
                      </IconButton>
                      <Typography color="#933E88">{option.label}</Typography>
                    </Stack>
                  </Stack>
                ) : (
                  option.label
                )}
              </Box>
            )}
            noOptionsText={
              buttonText ? (
                <Stack
                  width="100%"
                  height="40px"
                  direction="column"
                  justifyContent="center"
                  px={1}
                  sx={{ backgroundColor: '#F8EBF6', borderRadius: '8px', cursor: 'pointer' }}
                  onClick={handleCreateModal}
                >
                  <Stack direction="row" spacing={1} alignItems="center">
                    <IconButton
                      size="small"
                      sx={{ backgroundColor: 'white', ':hover': { backgroundColor: 'white' } }}
                    >
                      <AddIcon fontSize="small" sx={{ color: '#933E88' }} />
                    </IconButton>
                    <Typography color="#933E88">{buttonText}</Typography>
                  </Stack>
                </Stack>
              ) : (
                'No Options'
              )
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label={label}
                size={size}
                required={required}
                inputProps={{
                  ...params.inputProps,
                  autoComplete: 'new-password',
                }}
              />
            )}
          />
        );
      }}
    />
  );
};

OspSelectAutocomplete.propTypes = {
  name: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
      isButton: PropTypes.bool, // Add this for button options
    })
  ).isRequired,
  defaultValue: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string,
        label: PropTypes.string,
      })
    ),
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
    PropTypes.object,
  ]),
  label: PropTypes.string,
  required: PropTypes.bool,
  handleTextFieldChange: PropTypes.func,
  multiple: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  size: PropTypes.any,
  handleCreateModal: PropTypes.any,
  buttonText: PropTypes.any
};

export default OspSelectAutocomplete;
