import { lazy, Suspense } from 'react';
import { Outlet, Navigate } from 'react-router-dom';

import { AuthRoles } from 'src/routes/routes/paths';

import AllDeposits from 'src/pages/Admin/Deposit';
import AllAccounts from 'src/pages/Admin/Account';
import AllTrasfers from 'src/pages/Admin/Transfer';
import SettingsPage from 'src/pages/Admin/settings';
import LoginAdmin from 'src/assets/other/goldenBg.svg';
import CreateDeposit from 'src/pages/Admin/Deposit/depositCreate';
import CreateTransfer from 'src/pages/Admin/Transfer/transferCreate';

import { SplashScreen } from 'src/components/loading-screen';

const AuthLayout = lazy(() => import('src/layouts/auth/authLayout'));
const LogoutPage = lazy(() => import('src/pages/Common/Auth/LogoutPage'));
const LoginAdminPage = lazy(() => import('src/pages/Common/Auth/Login'));

const CompactLayout = lazy(() => import('src/layouts/compact'));
const ForgotPasswordPage = lazy(() => import('src/pages/Common/Auth/ForgotPassword'));
const NewPasswordView = lazy(() => import('src/sections/auth/newPassword'));
const AllClients = lazy(() => import('src/pages/Admin/Client'));
const AllBankDepositCards = lazy(() => import('src/pages/Admin/BankDepositCard'));
const CreateClient = lazy(() => import('src/pages/Admin/Client/clientCreate'));
const ClientView = lazy(() => import('src/pages/Admin/Client/clientView'));
const UpdateClient = lazy(() => import('src/pages/Admin/Client/clientUpdate'));

const CreateCompanyBank = lazy(() => import('src/pages/Admin/CompanyBank/companyBankCreate'));
const CreateBankDepositCard = lazy(
  () => import('src/pages/Admin/BankDepositCard/bankDepositCardCreate')
);
const UpdateCompanyBank = lazy(() => import('src/pages/Admin/CompanyBank/companyBankUpdate'));
const UpdateDepositCard = lazy(
  () => import('src/pages/Admin/BankDepositCard/bankDepoitCardUpdate')
);
const AllCompanyBanks = lazy(() => import('src/pages/Admin/CompanyBank'));
const CompanyBankView = lazy(() => import('src/pages/Admin/CompanyBank/bankView'));

const Page500 = lazy(() => import('src/pages/Common/Static/500'));
const Page403 = lazy(() => import('src/pages/Common/Static/403'));
const Page404 = lazy(() => import('src/pages/Common/Static/404'));

const publicRoutes = [
  {
    element: (
      <Suspense fallback={<SplashScreen />}>
        <Outlet />
      </Suspense>
    ),
    children: [
      {
        path: 'login',
        element: (
          <AuthLayout image={LoginAdmin} title="Authenticate as an administrator" userRole="admin">
            <LoginAdminPage role={AuthRoles.admin} />
          </AuthLayout>
        ),
      },
      {
        path: 'forgot-password',
        element: (
          <AuthLayout image={LoginAdmin} title="Authenticate as an administrator" userRole="admin">
            <ForgotPasswordPage />
          </AuthLayout>
        ),
      },
      {
        path: 'reset-password',
        element: (
          <AuthLayout image={LoginAdmin} title="Authenticate as an administrator" userRole="admin">
            <NewPasswordView />
          </AuthLayout>
        ),
      },
    ],
  },
  {
    element: (
      <CompactLayout>
        <Suspense fallback={<SplashScreen />}>
          <Outlet />
        </Suspense>
      </CompactLayout>
    ),
    children: [
      { path: '500', element: <Page500 /> },
      { path: '404', element: <Page404 /> },
      { path: '403', element: <Page403 /> },
    ],
  },
];

const authRoutes = [
  {
    path: 'auth',
    element: (
      <Suspense fallback={<SplashScreen />}>
        <Outlet />
      </Suspense>
    ),

    children: [
      {
        path: 'logout',
        element: <LogoutPage />,
      },
      { path: '404', element: <Page404 isInner /> },
      { path: '*', element: <Navigate to="/404" /> },
    ],
  },
];

// super admin
const adminRoutes = [
  {
    path: 'settings',
    element: <SettingsPage />,
  },
  {
    path: 'client',
    element: <AllClients />,
  },
  {
    path: 'client/create/new',
    element: <CreateClient />,
  },
  {
    path: 'client/single/:id',
    element: <ClientView />,
  },
  {
    path: 'client/update/:id',
    element: <UpdateClient />,
  },
  {
    path: 'company-bank/create/new',
    element: <CreateCompanyBank />,
  },
  {
    path: 'company-bank/edit/:id',
    element: <UpdateCompanyBank />,
  },
  {
    path: 'company-bank',
    element: <AllCompanyBanks />,
  },

  {
    path: 'company-bank/single/:id',
    element: <CompanyBankView />,
  },
  {
    path: 'bank-deposit-card',
    element: <AllBankDepositCards />,
  },

  {
    path: 'deposit-card/create/new',
    element: <CreateBankDepositCard />,
  },
  {
    path: 'deposit-card/edit/:id',
    element: <UpdateDepositCard />,
  },

  {
    path: 'deposit',
    element: <AllDeposits />,
  },

  {
    path: 'deposit/create/new',
    element: <CreateDeposit />,
  },
  {
    path: 'trasfer',
    element: <AllTrasfers />,
  },

  {
    path: 'transfer/create/new',
    element: <CreateTransfer />,
  },

  {
    path: 'account',
    element: <AllAccounts />,
  },
];

export { authRoutes, adminRoutes, publicRoutes };
