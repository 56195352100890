import { createSlice } from '@reduxjs/toolkit';

import { covertToJSON } from '../../utils/stateToJson';
import {
  createClientBankAccount,
  deleteClientBankAccount,
  getAllClientBankAccounts,
  getClientBankAccountDetails,
  updateClientBankAccountDetails,
} from '../api/clientBankAccount';

export const clientBankAccountSlice = createSlice({
  name: 'clientBankAccount',
  initialState: {
    loading: false,
    error: {},
    clientBankAccounts: {},
    clientBankAccountDetails: {},
  },
  reducers: {
    clearStaffError: (state) => {
      state.error = true;
    },
  },
  extraReducers: {
    [createClientBankAccount.pending]: (state) => {
      state.loading = true;
    },
    [createClientBankAccount.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.clientBankAccounts;
      const newClientBankAccountList = jsonState?.results;

      const modifiedClientBankAccountList = {
        ...jsonState,
        results: Array.isArray(newClientBankAccountList)
          ? [...newClientBankAccountList, action.payload]
          : [action.payload],
      };
      state.loading = false;
      state.clientBankAccounts = modifiedClientBankAccountList;
      state.error = {};
    },
    [createClientBankAccount.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [getAllClientBankAccounts.pending]: (state) => {
      state.loading = true;
    },
    [getAllClientBankAccounts.fulfilled]: (state, action) => {
      state.loading = false;
      state.clientBankAccounts = action.payload;
      state.error = {};
    },
    [getAllClientBankAccounts.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [getClientBankAccountDetails.pending]: (state) => {
      state.loading = true;
    },
    [getClientBankAccountDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.clientBankAccountDetails = action.payload;
      state.error = {};
    },
    [getClientBankAccountDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    [updateClientBankAccountDetails.pending]: (state) => {
      state.loading = true;
    },
    [updateClientBankAccountDetails.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.clientBankAccounts;
      const modifiedClientBankAccountList = {
        ...jsonState,
        results: jsonState.results?.map((i) => (i.id === action.payload.id ? action?.payload : i)),
      };

      state.loading = false;
      state.clientBankAccounts = modifiedClientBankAccountList;
      state.error = {};
    },
    [updateClientBankAccountDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [deleteClientBankAccount.pending]: (state) => {
      state.loading = true;
    },
    [deleteClientBankAccount.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.clientBankAccounts;
      const modifiedClientBankAccountList = {
        ...jsonState,
        results: jsonState.results?.filter((mod) => mod?.id !== action.payload),
      };
      state.loading = false;
      state.clientBankAccounts = modifiedClientBankAccountList;
      state.error = {};
    },
    [deleteClientBankAccount.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
  },
});
export const { clearStaffError } = clientBankAccountSlice.actions;

export default clientBankAccountSlice.reducer;
