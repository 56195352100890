import { createAsyncThunk } from '@reduxjs/toolkit';

import { get } from './http';
import { activeSnack } from '../store/common';

export const getAllUsers = createAsyncThunk(
  'user/list',
  async ({ page, search, dispatch, limit }) => {
    try {
      const response = await get(
        `/users/user-account/?page=${page ?? 1}&search=${search ?? ''}&pageSize=${limit || 10}`
      );
      console.log(response);
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getUserDetails = createAsyncThunk('user/details', async ({ dispatch }) => {
  try {
    const response = await get(`/users/admin-user/`);
    if (response) {
      return response;
    }
    return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
  } catch (error) {
    dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
    throw error?.response?.data?.message;
  }
});
