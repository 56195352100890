import { m } from 'framer-motion';
import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

import Box from '@mui/material/Box';
import { Chip } from '@mui/material';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import { alpha } from '@mui/material/styles';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import { useRouter } from 'src/routes/hooks';
import { paths } from 'src/routes/routes/paths';

import { useResponsive } from 'src/hooks/use-responsive';

import { getUserDetails } from 'src/server/api/user';
import { getConstants } from 'src/server/api/settings';

import Iconify from 'src/components/iconify';
import { varHover } from 'src/components/animate';
import { useSettingsContext } from 'src/components/settings';
import TextTruncate from 'src/components/Typography/TextTruncate';
import CustomPopover, { usePopover } from 'src/components/custom-popover';

// ----------------------------------------------------------------------

export default function AccountPopover({ changeTheme }) {
  const router = useRouter();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const mdUp = useResponsive('up', 'md');
  const settings = useSettingsContext();
  const { userDetails } = useSelector((state) => ({
    userDetails: state.user.userDetails,
  }));

  const popover = usePopover();
  const OPTIONS = [
    {
      label: 'Home',
      linkTo: paths.dashboard.root,
    },
    {
      label: 'Profile',
      linkTo: paths.dashboard.root,
    },
  ];

  // const signOff = async () => {
  //   popover.onClose();
  //   navigate(paths.auth.logout);
  //   if (changeTheme) {
  //     changeTheme('light');
  //   }
  //   settings.onUpdate('themeLayout', settings.themeLayout === 'mini' ? 'vertical' : 'vertical');
  // };

  const logout = () => {
    popover.onClose();
    navigate(paths.public.login);
    sessionStorage.clear();
    localStorage.clear('userData');
    if (changeTheme) {
      changeTheme('light');
    }
    settings.onUpdate('themeLayout', settings.themeLayout === 'mini' ? 'vertical' : 'vertical');
  };

  const handleClickItem = (path) => {
    popover.onClose();
    router.push(path);
  };

  useEffect(() => {
    dispatch(getUserDetails({ dispatch }));
  }, [dispatch]);

  useEffect(() => {
    dispatch(getConstants({ dispatch }));
  }, [dispatch]);

  return (
    <>
      <IconButton
        onClick={() => navigate(paths.dashboard.settings)}
        sx={{ borderRadius: '12px', backgroundColor: '#919EAB1F', mr: 1 }}
      >
        <Iconify icon="solar:settings-bold-duotone" width={24} />
        <Typography variant="subtitle2" color="black" pl={1}>
          Default Configuration
        </Typography>
      </IconButton>
      <IconButton
        component={m.button}
        whileTap="tap"
        whileHover="hover"
        variants={varHover(1.05)}
        onClick={popover.onOpen}
        sx={{
          width: 40,
          height: 40,
          background: (theme) => alpha(theme.palette.grey[500], 0.08),
          ...(popover.open && {
            background: (theme) =>
              `linear-gradient(135deg, ${theme.palette.primary.light} 0%, ${theme.palette.primary.main} 100%)`,
          }),
        }}
      >
        <Avatar
          src={
            userDetails?.image && userDetails.image.length > 0
              ? userDetails.image[0].url
              : 'default-image-url'
          }
          alt={userDetails?.username}
          sx={{
            width: 36,
            height: 36,
            border: (theme) => `solid 2px ${theme.palette.background.default}`,
          }}
        >
          {userDetails?.username?.charAt(0).toUpperCase()}
        </Avatar>
      </IconButton>

      {mdUp && (
        <Stack onClick={popover.onOpen} alignItems="center">
          <Typography variant="subtitle2" noWrap sx={{ textTransform: 'capitalize', display: 'flex', alignItems: 'center' }}>
            {userDetails?.username}
            {popover.open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </Typography>
        </Stack>
      )}

      <CustomPopover open={popover.open} onClose={popover.onClose} sx={{ width: 200, p: 0 }}>
        <Stack sx={{ position: 'relative' }}>
          <Box sx={{ p: 2, pb: 1.5 }}>
            <Typography
              variant="subtitle2"
              noWrap
              sx={{ textTransform: 'capitalize', display: 'flex', alignItems: 'center', mb: 1 }}
            >
              <TextTruncate text={userDetails?.username} width={97} />
              <Chip
                sx={{ ml: 1 }}
                label={userDetails?.role}
                variant="outlined"
                color="success"
                size="small"
              />
            </Typography>

            <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
              {userDetails?.email}
            </Typography>
          </Box>
        </Stack>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Stack sx={{ p: 1 }}>
          {OPTIONS.map((option) => (
            <MenuItem key={option.label} onClick={() => handleClickItem(option.linkTo)}>
              {option.label}
            </MenuItem>
          ))}
        </Stack>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Stack>
          <MenuItem
            sx={{ m: 1, fontWeight: 'fontWeightBold', color: 'error.main' }}
            onClick={logout}
          >
            Logout
          </MenuItem>
          {/* <Tooltip title="Logout from all device">
            <MenuItem id="signOffId" onClick={signOff} sx={{ m: 1 }}>
              Sign Off
            </MenuItem>
          </Tooltip> */}
        </Stack>
      </CustomPopover>
    </>
  );
}

AccountPopover.propTypes = {
  changeTheme: PropTypes.any,
};
