import { createAsyncThunk } from '@reduxjs/toolkit';

import { paths } from 'src/routes/routes/paths';

import { get, post } from './http';
import { activeSnack } from '../store/common';

export const getAllTransfers = createAsyncThunk(
  'transfer/list',
  async ({
    page,
    sort,
    search,
    dispatch,
    client,
    dateFrom,
    transferCurrency,
    ordering,
    limit,
    status,
    dateTo,
    withdrawalType,
  }) => {
    try {
      const response = await get(
        `operations/transfer/?page_=${page ?? 1}&client=${client ?? ''}&page_size=${
          limit || 10
        }&transfer_currency=${transferCurrency ?? ''}&date_from=${dateFrom ?? ''}&date_to=${
          dateTo ?? ''
        }&ordering=${ordering ?? ''}&search=${search ?? ''}&status=${
          status || ''
        }&withdrawal_type=${withdrawalType ?? ''}`
      );
      if (response) {
        
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  } 
);

export const createTransfer = createAsyncThunk(
  'transfer/create',
  async ({ state, dispatch, navigate }) => {
    try {
      const URL = `/operations/transfer/`;
      const response = await post(URL, state);
      if (response) {
        if (navigate) {
          navigate(paths.dashboard.trasfer);
        }
        dispatch(activeSnack({ type: 'success', message: 'Deposit Created Successfully' }));
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
export const getAllCounts = createAsyncThunk('otherproduct/count', async ({ dispatch }) => {
  try {
    const response = await get(`/operations/transfer-status-count/`);
    return response;
  } catch (error) {
    dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
    throw error?.response?.data?.message;
  }
});
// export const createDeposit = createAsyncThunk(
//   'deposit/create',
//   async ({ state, dispatch, navigate }) => {
//     try {
//       const URL = `/operations/deposit/`;
//       const response = await post(URL, state);
//       if (response) {
//         if (navigate) {
//           navigate(paths.dashboard.deposit);
//         }
//         dispatch(activeSnack({ type: 'success', message: 'Deposit Created Successfully' }));
//         return response;
//       }
//       return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
//     } catch (error) {
//       dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
//       throw error?.response?.data?.message;
//     }
//   }
// );

// export const deleteDeposit = createAsyncThunk('deposit/delete', async ({ depositId, dispatch }) => {
//   try {
//     const response = await del(`/operations/deposit/${depositId}/`);
//     if (response) {
//       dispatch(activeSnack({ type: 'success', message: 'Deposit Deleted Successfully' }));
//       return depositId;
//     }
//     return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
//   } catch (error) {
//     dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
//     throw error?.response?.data?.message;
//   }
// });
