import { createSlice } from '@reduxjs/toolkit';

import { getAllUsers, getUserDetails } from '../api/user';

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    loading: false,
    error: {},
    // staff
    userList: {},
    userDetails: {},
    staffDropList: [],
  },
  reducers: {
    clearStaffError: (state) => {
      state.error = true;
    },
  },
  extraReducers: {
    [getAllUsers.pending]: (state) => {
      state.loading = true;
    },
    [getAllUsers.fulfilled]: (state, action) => {
      state.loading = false;
      state.userList = action.payload;
      state.error = {};
    },
    [getAllUsers.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [getUserDetails.pending]: (state) => {
      state.loading = true;
    },
    [getUserDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.userDetails = action.payload;
      state.error = {};
    },
    [getUserDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
  },
});
export const { clearStaffError } = userSlice.actions;

export default userSlice.reducer;
