import { createAsyncThunk } from '@reduxjs/toolkit';

// import { paths } from 'src/routes/routes/paths';

import { paths } from 'src/routes/routes/paths';

import { get, put, post } from './http';
import { activeSnack } from '../store/common';

export const getAllBankDepositCards = createAsyncThunk(
  'deposit-card/list',
  async ({ page, search, dispatch, limit }) => {
    try {
      const response = await get(
        `/clients/bank-deposit-card/?page=${page ?? 1}&search=${search ?? ''}&page_size=${
          limit || 10
        }`
      );
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getBankDepositCardDetails = createAsyncThunk(
  'deposit-card/details',
  async ({ bankDepositCardId, dispatch }) => {
    try {
      const response = await get(`/clients/bank-deposit-card/${bankDepositCardId}`);
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const createBankDepositCard = createAsyncThunk(
  'deposit-card/create',
  async ({ state, dispatch, navigate }) => {
    try {
      const response = await post(`/clients/bank-deposit-card/`, state);
      if (response) {
        if (navigate) {
          navigate(paths.dashboard.bank_deposit_card);
        }
        dispatch(activeSnack({ type: 'success', message: 'Deposit card created successfully' }));
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const updateBankDepositCard = createAsyncThunk(
  'deposit-card/update',
  async ({ state, bankDepositCardId, dispatch, navigate }) => {
    try {
      const response = await put(`/clients/bank-deposit-card/${bankDepositCardId}/`, state);
      if (response) {
        if (navigate) {
          navigate(paths.dashboard.bank_deposit_card);
        }
        dispatch(activeSnack({ type: 'success', message: 'Deposit card updated successfully' }));
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
