import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import React, { useState, useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';

import { LoadingButton } from '@mui/lab';
import {
  Box,
  Card,
  Grid,
  Stack,
  Button,
  Avatar,
  Divider,
  Checkbox,
  Typography,
  InputAdornment,
  FormControlLabel,
} from '@mui/material';

import useConvertChoice from 'src/routes/hooks/use-convert-choice';

import CashImage from 'src/assets/other/cash.svg'
import BankImage from 'src/assets/other/bank_atm.svg'
import { createTransfer } from 'src/server/api/Transfer';
import { getAllClientsDropdown } from 'src/server/api/client';
import { getAllCompanyBanksDropdown } from 'src/server/api/companyBank';

import { RHFTextField } from 'src/components/hook-form';
import FormProvider from 'src/components/hook-form/form-provider';
import OspSelectAutocomplete from 'src/components/hook-form/rhf-OspSelectAutocomplete';

function CreateTransferForm() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { clientDropdownList, companyBankDropdownList } = useSelector((state) => ({
    clientDropdownList: state.client.clientDropdownList,
    companyBankDropdownList: state.companyBank.companyBankDropdownList,
  }));

  console.log('companyBankDropdownList', companyBankDropdownList);

  const [selectedCurrency, setSelectedCurrency] = useState(111);
  const [withdrawalType, setWithdrawalType] = useState(111);
  const [selectedTransferRate, setSelectedTransferRate] = useState(111);

  const [searchTextClient, setSearchTextClient] = useState('');
  const [searchTextCompanyBank, setSearchTextCompanyBank] = useState('');

  const validationSchema = Yup.object().shape({
    date: Yup.string().required('Date is required'),
    client: Yup.object().required('Client is required'),
    company_bank_account: Yup.object().required('Company Bank Account is required'),
    deposit_amount: Yup.number().required('Deposit Amount is required'),
    client_amount_transfer_rate: Yup.number().required('Transfer Rate is required'),
    client_received_amount: Yup.number().required('Amount Received is required'),
  });

  const defaultValues = {
    date: '',
    client: {},
    company_bank_account: {},
    deposit_amount: '',
    client_amount_transfer_rate: '',
    client_received_amount: '',
    is_service_charge_accountable_by_client: false,
  };

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = methods;

  console.log('errors', errors);

  const selectedClient = watch('client');
  const selectedCompanyBank = watch('company_bank_account');

  const onSubmit = (data) => {
    const formattedData = {
      ...data,
      transfer_currency: selectedCurrency,
      withdrawal_type: withdrawalType,
      client_transfer_rate_type: selectedTransferRate,
      is_service_charge_accountable_by_client:
        data?.is_service_charge_accountable_by_client || false,
      company_bank_account: data?.company_bank_account?.value || null,
      client: data.client?.value || null,
    };
    console.log('Form Data:', formattedData);
    const createCredentials = {
      state: formattedData,
      dispatch,
      navigate,
    };
    dispatch(createTransfer(createCredentials));
  };

  const withdrawalTypeChoice = useConvertChoice('v1.operations', 'WithdrawalTypeChoice');
  const transferCurrencyChoice = useConvertChoice('v1.operations', 'TransferCurrencyChoice');
  const TransferRateTypeChoice = useConvertChoice('v1.operations', 'ClientTransferRateTypeChoice');

  const optionTransferCurrencyChoice = [
    ...(transferCurrencyChoice || []).map((data) => ({
      label: data?.label,
      value: data?.value,
    })),
  ];

  const optionWithdrawalTypeChoice = [
    ...(withdrawalTypeChoice || []).map((data) => ({
      label: data?.label,
      value: data?.value,
    })),
  ];

  const optionTransferRateTypeChoice = [
    ...(TransferRateTypeChoice || []).map((data) => ({
      label: data?.label,
      value: data?.value,
    })),
  ];

  useEffect(() => {
    const credentials = { page: 1, search: searchTextCompanyBank || '', limit: 10, dispatch };
    dispatch(getAllCompanyBanksDropdown(credentials));
  }, [dispatch, searchTextCompanyBank]);

  const optionCompanyBanks = [
    ...(companyBankDropdownList || []).map((data) => ({
      label: data?.title,
      value: data?.id,
      ledgerBalance: data?.ledger_balance,
    })),
  ];

  useEffect(() => {
    const credentials = { page: 1, search: searchTextClient || '', limit: 10, dispatch };
    dispatch(getAllClientsDropdown(credentials));
  }, [dispatch, searchTextClient]);

  const optionClients = [
    ...(clientDropdownList || []).map((data) => ({
      label: data?.name,
      value: data?.id,
      ledgerBalance: data?.ledger_balance,
    })),
  ];

  const handleSearchClient = (value) => {
    setSearchTextClient(value);
  };
  const handleSearchCompanyBank = (value) => {
    setSearchTextCompanyBank(value);
  };

  return (
    <Grid container sx={{ mt: 4 }}>
      <Grid item xs={12} lg={8}>
        <Card sx={{ p: 4 }}>
          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            {/* Header Section */}
            <Stack direction="row" justifyContent="space-between" alignItems="center" pb={3}>
              <Typography variant="h6" sx={{ flexShrink: 0 }}>
                Add Details
              </Typography>

              <RHFTextField
                name="date"
                label="Date"
                size="small"
                type="date"
                sx={{
                  width: '200px',
                  ml: 2,
                }}
                InputLabelProps={{ shrink: true }}
              />
            </Stack>

            {/* Client & Bank Account */}
            <Grid lg={12}>
              <Stack direction="row" spacing={2} mb={3} alignItems="flex-start">
                {/* Client Autocomplete */}
                <Box sx={{ flex: 1 }}>
                  <OspSelectAutocomplete
                    name="client"
                    control={control}
                    label="Client"
                    options={optionClients}
                    handleTextFieldChange={handleSearchClient}
                    required
                  />
                  {selectedClient?.value && (
                    <Box
                      sx={{
                        mt: 1,
                        px: 1,
                        py: 0.2,
                        backgroundColor: '#A7009414',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <Typography variant="body2" sx={{ color: '#118D57' }}>
                        BAL
                      </Typography>
                      <Typography variant="body2" sx={{ ml: 1, color: '#006C9C' }}>
                        SR
                      </Typography>
                      <Typography variant="body1" sx={{ ml: 1 }}>
                        {selectedClient?.ledgerBalance === null
                          ? '0'
                          : selectedClient?.ledgerBalance || ''}
                      </Typography>
                    </Box>
                  )}
                </Box>

                {/* Company Bank Account Autocomplete */}
                <Box sx={{ flex: 1 }}>
                  <OspSelectAutocomplete
                    name="company_bank_account"
                    control={control}
                    label="Company Bank Account"
                    options={optionCompanyBanks}
                    handleTextFieldChange={handleSearchCompanyBank}
                    required
                  />
                  {selectedCompanyBank?.value && (
                    <Box
                      sx={{
                        mt: 1,
                        px: 1,
                        py: 0.2,
                        backgroundColor: '#A7009414',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <Typography variant="body2" sx={{ color: '#118D57' }}>
                        BAL
                      </Typography>
                      <Typography variant="body2" sx={{ ml: 1, color: '#006C9C' }}>
                        SR
                      </Typography>
                      <Typography variant="body1" sx={{ ml: 1 }}>
                        {selectedCompanyBank?.ledgerBalance === null
                          ? '0'
                          : selectedCompanyBank?.ledgerBalance}
                      </Typography>
                    </Box>
                  )}
                </Box>
              </Stack>
            </Grid>

            {/* Transfer Currency & Withdrawal Type */}
            <Grid container spacing={2} alignItems="center" mb={3}>
              <Grid item xs={12} md={6}>
                <Typography variant="body2" mb={2} gutterBottom>
                  Transfer Currency
                </Typography>
                <Stack direction="row" spacing={2}>
                  {optionTransferCurrencyChoice?.map((currency) => (
                    <Button
                      name="transfer_currency"
                      key={currency.value}
                      variant="contained"
                      onClick={() => setSelectedCurrency(currency.value)}
                      sx={{
                        minWidth: 130,
                        backgroundColor:
                          selectedCurrency === currency.value ? '#933E88' : '#f3f3f3',
                        color: selectedCurrency === currency.value ? '#fff' : '#000',
                        '&:hover': {
                          backgroundColor:
                            selectedCurrency === currency.value ? '#933E88' : '#e0e0e0',
                        },
                        py: 1,
                      }}
                    >
                      {currency.label}
                    </Button>
                  ))}
                </Stack>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="body2" mb={2} gutterBottom>
                  Withdrawal Type
                </Typography>
                <Stack direction="row" spacing={2}>
                  {optionWithdrawalTypeChoice?.map((type) => (
                   <Button
                   name="withdrawal_type"
                   key={type.value}
                   variant="contained"
                   onClick={() => setWithdrawalType(type.value)}
                   startIcon={
                     <Avatar
                       src={type.value === 111 ? CashImage : BankImage}
                       alt={type.value === 111 ? "Cash Icon" : "Bank Icon"}
                       sx={{ width: 17, height: 17 }} 
                     />
                   }
                   sx={{
                     minWidth: 130,
                     backgroundColor: withdrawalType === type.value ? '#933E88' : '#f3f3f3',
                     color: withdrawalType === type.value ? '#fff' : '#000',
                     '&:hover': {
                       backgroundColor: withdrawalType === type.value ? '#933E88' : '#e0e0e0',
                     },
                     py: 1,
                   }}
                 >
                   {type.label}
                 </Button>
                  ))}
                </Stack>
              </Grid>
            </Grid>

            {/* Transfer Rate Type */}
            <Grid container spacing={2} mb={3} mt={3}>
              <Grid item xs={12} md={6}>
                <Typography variant="body2" mb={2} gutterBottom>
                  Transfer Rate Type
                </Typography>
                <Stack direction="row" spacing={2}>
                  {optionTransferRateTypeChoice?.map((rate) => (
                    <Button
                      name="client_transfer_rate_type"
                      key={rate.value}
                      variant="contained"
                      onClick={() => setSelectedTransferRate(rate.value)}
                      sx={{
                        minWidth: 130,
                        backgroundColor:
                          selectedTransferRate === rate.value ? '#933E88' : '#f3f3f3',
                        color: selectedTransferRate === rate.value ? '#fff' : '#000',
                        '&:hover': {
                          backgroundColor:
                            selectedTransferRate === rate.value ? '#933E88' : '#e0e0e0',
                        },
                        py: 1,
                      }}
                    >
                      {rate.label}
                    </Button>
                  ))}
                </Stack>
              </Grid>
              <Grid item xs={12} md={6} mt={5}>
                <FormControlLabel
                  sx={{ width: '100%' }}
                  control={
                    <Checkbox {...methods.register('is_service_charge_accountable_by_client')} />
                  }
                  label="Is Service Charge Accountable by Client"
                />
              </Grid>
            </Grid>

            {/* Deposit Amount, Transfer Rate, Amount Received */}
            <Grid container spacing={2} mb={3}>
              <Grid item xs={12} md={4}>
                <RHFTextField
                  name="deposit_amount"
                  label="Deposit Amount"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Typography variant="body2" color="textSecondary" sx={{ fontWeight: 500 }}>
                          SR
                        </Typography>
                      </InputAdornment>
                    ),
                  }}
                  sx={{
                    width: '100%', // Adjust width as needed
                    '& .MuiInputBase-input': {},
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <RHFTextField name="client_amount_transfer_rate" label="Transfer Rate" />
              </Grid>
              <Grid item xs={12} md={4}>
                <RHFTextField name="client_received_amount" label="Amount Received" />
              </Grid>
            </Grid>

            <Divider sx={{ borderStyle: 'dotted', my: 3 }} />

            {/* Action Buttons */}
            <Stack direction="row" justifyContent="flex-end" spacing={2}>
              <LoadingButton
                type="submit"
                variant="contained"
                sx={{
                  backgroundColor: '#C050B3',
                  color: '#fff',
                  '&:hover': { backgroundColor: '#A0409B' },
                }}
              >
                Create
              </LoadingButton>
              <Button variant="outlined" sx={{ color: '#000' }}>
                Cancel
              </Button>
            </Stack>
          </FormProvider>
        </Card>
      </Grid>
    </Grid>
  );
}

export default CreateTransferForm;
