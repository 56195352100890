import { createAsyncThunk } from '@reduxjs/toolkit';

import { paths } from 'src/routes/routes/paths';

import { useSetConstant } from 'src/hooks/useHandleSessions';

import { get, put } from './http';
import { activeSnack } from '../store/common';

export const getSettingsDetails = createAsyncThunk('settings/details', async ({ dispatch }) => {
  try {
    const response = await get(`/companies/settings/`);
    if (response) {
      return response;
    }
    return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
  } catch (error) {
    dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
    throw error?.response?.data?.message;
  }
});

export const updateSettingsDetails = createAsyncThunk(
  'settings/update',
  async ({ state, dispatch, navigate }, { rejectWithValue }) => {
    try {
      const response = await put(`/companies/settings/`, state);
      if (response) {
        dispatch(activeSnack({ type: 'success', message: 'Settings updated successfully!' }));
        if (navigate) {
          navigate(paths.dashboard.root);
        }
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const getConstants = createAsyncThunk('constant/values', async ({ dispatch }) => {
  try {
    const response = await get(`/constants/`);
    if (response) {
      useSetConstant(response);
      return response;
    }
    return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
  } catch (error) {
    dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
    throw error?.response?.data?.message;
  }
});
