import React from 'react';

import { Container } from '@mui/material';

import { paths } from 'src/routes/routes/paths';

import { MetaHelmet } from 'src/layouts/Helmet/Helmet';

import { useSettingsContext } from 'src/components/settings';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs/custom-breadcrumbs';

import CreateTransferForm from 'src/sections/Transfer/transferForm';

function CreateTransfer() {
  const settings = useSettingsContext();
  return (
    <>
      <MetaHelmet title="Transfer " />
      <Container maxWidth={settings.themeStretch ? false : 'xl'}>
        <CustomBreadcrumbs
          heading="Add a transfer "
          links={[
            {
              name: 'Dashboard',
              href: paths.dashboard.root,
            },
            {
              name: 'Transfer',
              href: paths.dashboard.deposit,
            },
            {
              name: 'Add Transfer',
            },
          ]}
          sx={{
            mb: { xs: 3, md: 5 },
          }}
        />
        <CreateTransferForm />
      </Container>
    </>
  );
}

export default CreateTransfer;
