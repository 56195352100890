import { createAsyncThunk } from '@reduxjs/toolkit';

import { paths } from 'src/routes/routes/paths';

import { del, get, put, post } from './http';
import { activeSnack } from '../store/common';

export const createClient = createAsyncThunk(
  'client/create',
  async ({ state, dispatch, navigate, onClose, setValue }) => {
    try {
      const URL = `/clients/client/`;
      const response = await post(URL, state);
      if (response) {
        if (navigate) {
          navigate(paths.dashboard.client_view(response?.id));
        }
        if (onClose) {
          onClose();
          dispatch(getAllClients({ dispatch }));
        }
        if (setValue) {
          setValue('client', { label: response?.name, value: response?.id });
        }
        dispatch(activeSnack({ type: 'success', message: 'Client Created Successfully' }));
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getAllClients = createAsyncThunk(
  'client/list',
  async ({ page, search, dispatch, limit }) => {
    try {
      const response = await get(
        `/clients/client/?page=${page ?? 1}&search=${search ?? ''}&page_size=${limit || 10}`
      );
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getClientDetails = createAsyncThunk(
  'client/single',
  async ({ clientId, dispatch }) => {
    try {
      const response = await get(`/clients/client/${clientId}/`);
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const updateClientDetails = createAsyncThunk(
  'client/update',
  async ({ state, clientId, dispatch, navigate }) => {
    try {
      const URL = `/clients/client/${clientId}/`;

      const response = await put(URL, state);
      if (response) {
        if (navigate) {
          navigate(paths.dashboard.client_view(response?.id));
        }
        dispatch(activeSnack({ type: 'success', message: 'Client Updated Successfully' }));
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const deleteClient = createAsyncThunk('client/delete', async ({ clientId, dispatch }) => {
  try {
    const response = await del(`/clients/client/${clientId}/`);
    if (response) {
      dispatch(activeSnack({ type: 'success', message: 'Client Deleted Successfully' }));
      return clientId;
    }
    return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
  } catch (error) {
    dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
    throw error?.response?.data?.message;
  }
});

export const getAllClientsDropdown = createAsyncThunk(
  'client-dropdown/list',
  async ({ page, search, dispatch, limit }) => {
    try {
      const response = await get(
        `/clients/client-drop-down/?page=${page ?? 1}&search=${search ?? ''}&page_size=${
          limit || 10
        }`
      );
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
