import { createSlice } from '@reduxjs/toolkit';

import { covertToJSON } from '../../utils/stateToJson';
import {
  createClient,
  deleteClient,
  getAllClients,
  getClientDetails,
  updateClientDetails,
  getAllClientsDropdown,
} from '../api/client';

export const clientSlice = createSlice({
  name: 'client',
  initialState: {
    loading: false,
    error: {},
    clients: {},
    clientDropdownList:[],
    clientDetails: {},
  },
  reducers: {
    clearStaffError: (state) => {
      state.error = true;
    },

    clearClientDetails: (state) => {
      state.clientDetails = {};
    },
  },
  extraReducers: {
    [createClient.pending]: (state) => {
      state.loading = true;
    },
    [createClient.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.clients;
      const newClientList = jsonState?.results;

      const modifiedClientList = {
        ...jsonState,
        results: Array.isArray(newClientList)
          ? [...newClientList, action.payload]
          : [action.payload],
      };
      state.loading = false;
      state.clients = modifiedClientList;
      state.error = {};
    },
    [createClient.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [getAllClients.pending]: (state) => {
      state.loading = true;
    },
    [getAllClients.fulfilled]: (state, action) => {
      state.loading = false;
      state.clients = action.payload;
      state.error = {};
    },
    [getAllClients.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
// client dropdown list

    [getAllClientsDropdown.pending]: (state) => {
      state.loading = true;
    },
    [getAllClientsDropdown.fulfilled]: (state, action) => {
      state.loading = false;
      state.clientDropdownList = action.payload;
      state.error = {};
    },
    [getAllClientsDropdown.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },


    [getClientDetails.pending]: (state) => {
      state.loading = true;
    },
    [getClientDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.clientDetails = action.payload;
      state.error = {};
    },
    [getClientDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    [updateClientDetails.pending]: (state) => {
      state.loading = true;
    },
    [updateClientDetails.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.clients;
      const modifiedClientList = {
        ...jsonState,
        results: jsonState.results?.map((i) => (i.id === action.payload.id ? action?.payload : i)),
      };

      state.loading = false;
      state.clients = modifiedClientList;
      state.error = {};
    },
    [updateClientDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [deleteClient.pending]: (state) => {
      state.loading = true;
    },
    [deleteClient.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.clients;
      const modifiedClientList = {
        ...jsonState,
        results: jsonState.results?.filter((mod) => mod?.id !== action.payload),
      };
      state.loading = false;
      state.clients = modifiedClientList;
      state.error = {};
    },
    [deleteClient.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
  },
});
export const { clearStaffError, clearClientDetails } = clientSlice.actions;

export default clientSlice.reducer;
