// ----------------------------------------------------------------------

const ROOTS = {
  DASHBOARD: '/dashboard',
  AUTH: '/auth',
};

// ----------------------------------------------------------------------

export const paths = {
  public: {
    login: '/login',
    forgot_password: '/forgot-password',
    reset_password: '/reset-password',
  },
  // AUTH
  auth: {
    logout: `${ROOTS.AUTH}/logout`,
  },
  // DASHBOARD
  dashboard: {
    root: `${ROOTS.DASHBOARD}/app`,
    onBoarding: '/on-boarding',
    profile: `${ROOTS.DASHBOARD}/profile`,
    client: `${ROOTS.DASHBOARD}/client`,
    create_client: `${ROOTS.DASHBOARD}/client/create/new`,
    client_view: (id) => `${ROOTS.DASHBOARD}/client/single/${id}`,
    client_update: (id) => `${ROOTS.DASHBOARD}/client/update/${id}`,
    course: `${ROOTS.DASHBOARD}/course`,
    course_view: (id) => `${ROOTS.DASHBOARD}/course/${id}`,
    company_bank: `${ROOTS.DASHBOARD}/company-bank`,
    company_bank_view: (id) => `${ROOTS.DASHBOARD}/company-bank/single/${id}`,
    create_company_bank: `${ROOTS.DASHBOARD}/company-bank/create/new`,
    update_company_bank: (id) => `${ROOTS.DASHBOARD}/company-bank/edit/${id}`,
    bank_deposit_card: `${ROOTS.DASHBOARD}/bank-deposit-card`,
    create_deposit_card: `${ROOTS.DASHBOARD}/deposit-card/create/new`,
    update_deposit_card: (id) => `${ROOTS.DASHBOARD}/deposit-card/edit/${id}`,
    deposit: `${ROOTS.DASHBOARD}/deposit`,
    create_deposit: `${ROOTS.DASHBOARD}/deposit/create/new`,
    trasfer: `${ROOTS.DASHBOARD}/trasfer`,
    create_transfer: `${ROOTS.DASHBOARD}/transfer/create/new`,
    account: `${ROOTS.DASHBOARD}/account`,
    settings: `${ROOTS.DASHBOARD}/settings`,
  },
};

export const AuthRoles = {
  admin: 'Admin',
  partner: 'Partner',
  school: 'School',
  academy: 'Student',
};
