import { createAsyncThunk } from '@reduxjs/toolkit';

import { paths } from 'src/routes/routes/paths';

import { get, put, post } from './http';
import { activeSnack } from '../store/common';

export const getAllCompanyBanks = createAsyncThunk(
  'company-bank/list',
  async ({ page, search, dispatch, limit }) => {
    try {
      const response = await get(
        `/companies/company-bank-account/?page=${page ?? 1}&search=${search ?? ''}&page_size=${
          limit || 10
        }`
      );
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getAllCompanyBanksDropdown = createAsyncThunk(
  'company-bank-dropdown/list',
  async ({ page, search, dispatch, limit }) => {
    try {
      const response = await get(
        `/companies/company-bank-account-drop-down/?page=${page ?? 1}&search=${
          search ?? ''
        }&page_size=${limit || 10}`
      );
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const createCompanyBank = createAsyncThunk(
  'company-bank/create',
  async ({ state, dispatch, navigate, onClose, setValue }) => {
    try {
      const URL = `/companies/company-bank-account/`;
      const response = await post(URL, state);
      if (response) {
        if (navigate) {
          navigate(paths.dashboard.company_bank_view(response?.id));
        }
        if (onClose) {
          onClose();
          dispatch(getAllCompanyBanks({ dispatch }));
        }
        if (setValue) {
          setValue('company_bank_account', { label: response?.title, value: response?.id });
        }
        dispatch(activeSnack({ type: 'success', message: 'Company Bank Created Successfully' }));
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
      throw error?.response?.data;
    }
  }
);
export const updateCompanyBankDetails = createAsyncThunk(
  'company-bank/update',
  async ({ state, bankId, dispatch, navigate }) => {
    try {
      const URL = `companies/company-bank-account/${bankId}/`;

      const response = await put(URL, state);
      if (response) {
        if (navigate) {
          navigate(paths.dashboard.company_bank_view(response?.id));
        }
        dispatch(activeSnack({ type: 'success', message: 'Company Bank Updated Successfully' }));
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getCompanyBankDetails = createAsyncThunk(
  'company/single',
  async ({ companyBankId, dispatch }) => {
    try {
      const response = await get(`companies/company-bank-account/${companyBankId}`);
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
