import { createAsyncThunk } from '@reduxjs/toolkit';

import { del, get, put, post } from './http';
import { activeSnack } from '../store/common';

export const createClientBankAccount = createAsyncThunk(
  'client-bank-account/create',
  async ({ state, dispatch, handleClose, handleClear }) => {
    try {
      const URL = `/clients/client-bank-account/`;
      const response = await post(URL, state);
      if (response) {
        if (handleClose) {
          handleClose();
          dispatch(getAllClientBankAccounts({ dispatch, clientId: response?.client }));
        }
        if (handleClear) {
          handleClear();
        }
        dispatch(
          activeSnack({ type: 'success', message: 'ClientBankAccount Created Successfully' })
        );
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.error_message }));
      throw error?.response?.data?.error_message;
    }
  }
);

export const getAllClientBankAccounts = createAsyncThunk(
  'client-bank-account/list',
  async ({ page, search, dispatch, limit, clientId }) => {
    try {
      const response = await get(
        `/clients/client-bank-account/?page=${page ?? 1}&search=${search ?? ''}&page_size=${
          limit || 10
        }&client=${clientId || ''}`
      );
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getClientBankAccountDetails = createAsyncThunk(
  'client-bank-account/single',
  async ({ clientBankAccountId, dispatch }) => {
    try {
      const response = await get(`/clients/client-bank-account/${clientBankAccountId}/`);
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const updateClientBankAccountDetails = createAsyncThunk(
  'client-bank-account/update',
  async ({ state, clientBankAccountId, dispatch, handleClose, handleClear }) => {
    try {
      const URL = `/clients/client-bank-account/${clientBankAccountId}/`;

      const response = await put(URL, state);
      if (response) {
        if (handleClose) {
          handleClose();
        }
        if (handleClear) {
          handleClear();
        }
        dispatch(
          activeSnack({ type: 'success', message: 'ClientBankAccount Updated Successfully' })
        );
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.error_message }));
      throw error?.response?.data?.error_message;
    }
  }
);

export const deleteClientBankAccount = createAsyncThunk(
  'client-bank-account/delete',
  async ({ clientBankAccountId, dispatch, handleCloseDeleteDialog }) => {
    try {
      const response = await del(`/clients/client-bank-account/${clientBankAccountId}/`);
      if (response) {
        if (handleCloseDeleteDialog) {
          handleCloseDeleteDialog();
        }
        dispatch(
          activeSnack({ type: 'success', message: 'ClientBankAccount Deleted Successfully' })
        );
        return clientBankAccountId;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
