import { useMemo } from 'react';

import { paths } from 'src/routes/routes/paths';

// import Iconify from 'src/components/iconify';
import SvgColor from 'src/components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

// ----------------------------------------------------------------------

export function useNavData() {
  // sidebar data block
  const adminSideBarData = useMemo(
    () => [
      {
        subheader: 'OVERVIEW',
        items: [
          { title: 'Dashboard', path: `${paths.dashboard.root}`, icon: icon('ic_dashboard') },
        ],
      },
      {
        subheader: 'MANAGEMENT',
        items: [
          {
            title: 'Client',
            path: paths.dashboard.client,
            icon: icon('ic_user'),
          },
          {
            title: 'Company Bank',
            path: paths.dashboard.company_bank,
            icon: icon('ic_banking'),
          },
          {
            title: 'Bank Deposit Card',
            path: paths.dashboard.bank_deposit_card,
            icon: icon('ic_kanban'),
          },
          {
            title: 'Transfer',
            path: `${paths.dashboard.trasfer}?search=ongoing`,
            icon: icon('ic_external'),
            children: [
              {
                title: 'Ongoing Transfers',
                path: `${paths.dashboard.trasfer}?search=ongoing`,
              },
              {
                title: 'Transfer Profit',
                path: `${paths.dashboard.trasfer}?search=profit`,
              },
            ],
          },
          {
            title: 'Deposit',
            path: paths.dashboard.deposit,
            icon: icon('ic_invoice'),
          },
          {
            title: 'Accounts',
            path: `${paths.dashboard.account}?search=ledger-head`,
            icon: icon('ic_job'),
            children: [
              {
                title: 'Ledger Head',
                path: `${paths.dashboard.account}?search=ledger-head`,
              },
              {
                title: 'Ledger',
                path: `${paths.dashboard.account}?search=ledger`,
              },
              {
                title: 'Ledger Entries',
                path: `${paths.dashboard.account}?search=ledger-entry`,
              },
              {
                title: 'Contra',
                path: `${paths.dashboard.account}?search=contra`,
              },
              {
                title: 'Balance Sheet',
                path: `${paths.dashboard.account}?search=balance-sheet`,
              },
            ],
          },
        ],
      },
    ],
    []
  );

  return adminSideBarData;
}

// export function useNavData() {
//   const data = useMemo(() => adminSideBarData, []);

//   return data;
// }

// const ICONS = {
//   job: icon('ic_job'),
//   blog: icon('ic_blog'),
//   chat: icon('ic_chat'),
//   mail: icon('ic_mail'),
//   user: icon('ic_user'),
//   file: icon('ic_file'),
//   lock: icon('ic_lock'),
//   tour: icon('ic_tour'),
//   order: icon('ic_order'),
//   label: icon('ic_label'),
//   blank: icon('ic_blank'),
//   kanban: icon('ic_kanban'),
//   folder: icon('ic_folder'),
//   banking: icon('ic_banking'),
//   booking: icon('ic_booking'),
//   invoice: icon('ic_invoice'),
//   product: icon('ic_product'),
//   calendar: icon('ic_calendar'),
//   disabled: icon('ic_disabled'),
//   external: icon('ic_external'),
//   menuItem: icon('ic_menu_item'),
//   ecommerce: icon('ic_ecommerce'),
//   analytics: icon('ic_analytics'),
//   dashboard: icon('ic_dashboard'),
// };
