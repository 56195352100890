import { createSlice } from '@reduxjs/toolkit';

import { covertToJSON } from '../../utils/stateToJson';
import { getAllCounts, createTransfer, getAllTransfers } from '../api/Transfer';

export const trasferSlice = createSlice({
  name: 'transfer',
  initialState: {
    loading: false,
    error: {},
    allCounts: [],
    transfers: {},
    trasferDetails: {},
  },
  reducers: {
    clearStaffError: (state) => {
      state.error = true; 
    },
  },
  extraReducers: {
    [createTransfer.pending]: (state) => {
      state.loading = true;
    },
    [createTransfer.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.transfers;
      const newtransfersList = jsonState?.results;

      const modifiedtransfersList = {
        ...jsonState,
        results: Array.isArray(newtransfersList)
          ? [...newtransfersList, action.payload]
          : [action.payload],
      };
      state.loading = false;
      state.transfers = modifiedtransfersList;
      state.error = {};
    },
    [createTransfer.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [getAllTransfers.pending]: (state) => {
      state.loading = true;
    },
    [getAllTransfers.fulfilled]: (state, action) => {
      state.loading = false;
      state.transfers = action.payload;
      state.error = {};
    },
    [getAllTransfers.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [getAllCounts.pending]: (state) => {
      state.loading = true;
    },
    [getAllCounts.fulfilled]: (state, action) => {
      state.loading = false;
      state.allCounts = action.payload;
    },
    [getAllCounts.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    //   [getCombanyBankDetails.pending]: (state) => {
    //     state.loading = true;
    //   },
    //   [getCombanyBankDetails.fulfilled]: (state, action) => {
    //     state.loading = false;
    //     state.bankDetails = action.payload;
    //     state.error = {};
    //   },
    //   [getCombanyBankDetails.rejected]: (state, action) => {
    //     state.loading = false;
    //     state.error = action.error;
    //   },

    //   [updateCombanyBankDetails.pending]: (state) => {
    //     state.loading = true;
    //   },
    //   [updateCombanyBankDetails.fulfilled]: (state, action) => {
    //     const jsonState = covertToJSON(state)?.combanyBanks;
    //     const modifiedcombanyBankList = {
    //       ...jsonState,
    //       results: jsonState.results?.map((i) => (i.id === action.payload.id ? action?.payload : i)),
    //     };

    //     state.loading = false;
    //     state.combanyBanks = modifiedcombanyBankList;
    //     state.error = {};
    //   },
    //   [updateCombanyBankDetails.rejected]: (state, action) => {
    //     state.loading = false;
    //     state.error = action.error;
    //   },

    // [deleteDeposit.pending]: (state) => {
    //   state.loading = true;
    // },
    // [deleteDeposit.fulfilled]: (state, action) => {
    //   const jsonState = covertToJSON(state)?.deposits;
    //   const modifiedDepositList = {
    //     ...jsonState,
    //     results: jsonState.results?.filter((mod) => mod?.id !== action.payload),
    //   };
    //   state.loading = false;
    //   state.deposits = modifiedDepositList;
    //   state.error = {};
    // },
    // [deleteDeposit.rejected]: (state, action) => {
    //   state.loading = false;
    //   state.error = action.error;
    // },
  },
});
export const { clearStaffError } = trasferSlice.actions;

export default trasferSlice.reducer;
