import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { Button, Tooltip, TableRow, TableCell, IconButton, Typography } from '@mui/material';

import Iconify from 'src/components/iconify';

import DepositModal from './depositModal';

export default function DepositTableRow({ row, selected, onDeleteRow, loading }) {
  const { date, amount, client_name, company_bank_account_name, bank_deposit_card_name, id } = row;

  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  return (
    <>
      <TableRow hover selected={selected}>
        <TableCell sx={{ whiteSpace: 'nowrap' }}>
          {(() => {
            const formattedDate = new Date(date);
            return `${formattedDate.getDate()}-${
              formattedDate.getMonth() + 1
            }-${formattedDate.getFullYear()}`;
          })()}
        </TableCell>
        <TableCell sx={{ whiteSpace: 'nowrap' }}>{client_name || '------'}</TableCell>
        <TableCell sx={{ whiteSpace: 'nowrap' }}>{bank_deposit_card_name || '------'}</TableCell>
        <TableCell sx={{ whiteSpace: 'nowrap' }}>{company_bank_account_name || '------'}</TableCell>
        <TableCell sx={{ whiteSpace: 'nowrap' }}>
          <Typography variant="body2" color="#637381">
            SR{' '}
            <Typography variant="body2" component="span" color="#212B36" fontWeight={600}>
              {amount}
            </Typography>
          </Typography>
        </TableCell>

        <TableCell
          sx={{
            whiteSpace: 'nowrap',
            display: 'flex',
            justifyContent: 'end',
            alignItems: 'center',
            gap: 2,
          }}
        >
          <Button
            variant="contained"
            color="inherit"
            onClick={handleOpenModal}
            sx={{
              backgroundColor: '#00000014',
              color: '#000000',
              borderRadius: '50px',
              px: 3,
              ':hover': { backgroundColor: '#00000014', color: '#000000' },
            }}
          >
            View
          </Button>

          <Tooltip title="Delete">
            <IconButton onClick={() => onDeleteRow(id)} sx={{ color: 'error.main' }}>
              <Iconify icon="solar:trash-bin-trash-bold" />
            </IconButton>
          </Tooltip>
        </TableCell>
      </TableRow>

      <DepositModal open={openModal} onClose={handleCloseModal} data={row} />
    </>
  );
}

DepositTableRow.propTypes = {
  onDeleteRow: PropTypes.func,
  row: PropTypes.object,
  selected: PropTypes.bool,
  id: PropTypes.any,
  loading: PropTypes.any,
};
