import { configureStore } from '@reduxjs/toolkit';

import appReducer from './app';
import authReducer from './auth';
import UserReducer from './user';
import commonReducer from './common';
import clientReducer from './client';
import DepositReducer from './deposit';
import CountryReducer from './country';
import TransferReducer from './Transfer'
import settingsReducer from './settings';
import dashboardReducer from './dashboard';
import companyBankReducer from './companyBank';
import BankDepositCardReducer from './bankDepositCard';
import ClientBankAccountReducer from './clientBankAccount';


export default configureStore({
  reducer: {
    common: commonReducer,
    auth: authReducer,
    app: appReducer,
    dashboard: dashboardReducer,
    country: CountryReducer,
    user: UserReducer,
    client: clientReducer,
    clientBankAccount: ClientBankAccountReducer,
    companyBank: companyBankReducer,
    bankDepositCard: BankDepositCardReducer,
    deposit: DepositReducer,
    transfer: TransferReducer,
    settings: settingsReducer,
  },
});
