import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import React, { useMemo, useState, useEffect } from 'react';

import { LoadingButton } from '@mui/lab';
import {
  Box,
  Card,
  Grid,
  Stack,
  Button,
  Divider,
  TextField,
  Typography,
  InputAdornment,
} from '@mui/material';

import { paths } from 'src/routes/routes/paths';
import useConvertChoice from 'src/routes/hooks/use-convert-choice';

import { createDeposit } from 'src/server/api/deposit';
import { getAllClients, getClientDetails } from 'src/server/api/client';
import { getAllCompanyBanks, getCompanyBankDetails } from 'src/server/api/companyBank';
import { getAllBankDepositCards, getBankDepositCardDetails } from 'src/server/api/bankDepositCard';

import { RHFTextField } from 'src/components/hook-form';
import FormProvider from 'src/components/hook-form/form-provider';
import OspSelectAutocomplete from 'src/components/hook-form/rhf-OspSelectAutocomplete';

function CreateDepositForm() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    loading,
    companyBanks,
    clients,
    clientDetails,
    bankDetails,
    depositCards,
    bankDepositCardDetails,
  } = useSelector((state) => ({
    companyBanks: state.companyBank.companyBanks,
    clients: state.client.clients,
    clientDetails: state.client.clientDetails,
    bankDetails: state.companyBank.bankDetails,
    bankDepositCardDetails: state.bankDepositCard.depositCardDetails,
    loading: state.deposit.loading,
    depositCards: state.bankDepositCard.depositCards,
  }));

  const constantValues = useConvertChoice('v1.operations', 'DepositTypeChoices');

  const [searchTextCompanyBank, setSearchValueCompanyBank] = useState('');
  const [searchTextClient, setSearchValueClient] = useState('');
  const [searchTextDepositCard, setSearchTextDepositCard] = useState();

  useEffect(() => {
    const credentials = {
      page: 1,
      search: searchTextCompanyBank || '',
      limit: 10,
      dispatch,
    };
    dispatch(getAllCompanyBanks(credentials));
  }, [dispatch, searchTextCompanyBank]);

  useEffect(() => {
    const credentials = {
      page: 1,
      search: searchTextClient || '',
      limit: 10,
      dispatch,
    };
    dispatch(getAllClients(credentials));
  }, [dispatch, searchTextClient]);

  useEffect(() => {
    const credentials = {
      page: 1,
      search: searchTextDepositCard || '',
      limit: 10,
      dispatch,
    };
    dispatch(getAllBankDepositCards(credentials));
  }, [dispatch, searchTextDepositCard]);

  const optionCompanyBank = [
    ...(companyBanks?.results || []).map((result) => ({
      label: result?.title,
      value: result?.id,
    })),
  ];

  const optionClients = (clients?.results || []).map((result) => ({
    label: result?.name,
    value: result?.id,
  }));

  const optionDepositCards = (depositCards?.results || []).map((result) => ({
    label: result?.title,
    value: result?.id,
  }));

  const clientSchema = Yup.object().shape({
    depositType: Yup.number().required('Deposit Type is required'),
    company_bank_account: Yup.object().required('Company Bank Account is required'),
    client: Yup.object().required('Client is required'),
    bank_deposit_card: Yup.object(),
    amount: Yup.number().required('Amount is required'),
    note: Yup.string(),
    date: Yup.string().required('Date is required'),
  });

  const defaultValues = useMemo(() => {
    const today = new Date(); // Initialize today inside useMemo
    return {
      depositType: 111,
      company_bank_account: {},
      client: {},
      bank_deposit_card: {},
      amount: 0,
      note: '',
      date: today.toISOString().split('T')[0],
    };
  }, []);

  const methods = useForm({
    resolver: yupResolver(clientSchema),
    defaultValues,
  });

  const { handleSubmit, control, setValue, watch, register } = methods;

  // Watch fields
  const selectedClient = watch('client');
  const selectedCompanyBank = watch('company_bank_account');
  const selectedBankDepositCard = watch('bank_deposit_card');
  const selectedType = watch('depositType');

  const defaultClient = useMemo(() => {
    if (selectedBankDepositCard?.value) {
      return {
        label: bankDepositCardDetails?.client_name || '',
        value: bankDepositCardDetails?.client || '',
      };
    }
    return {};
  }, [
    bankDepositCardDetails?.client,
    bankDepositCardDetails?.client_name,
    selectedBankDepositCard?.value,
  ]);

  const defaultCompanyBankAccount = useMemo(() => {
    if (selectedBankDepositCard?.value) {
      return {
        label: bankDepositCardDetails?.company_bank_account_name || '',
        value: bankDepositCardDetails?.company_bank_account || '',
      };
    }
    return {};
  }, [
    bankDepositCardDetails?.company_bank_account,
    bankDepositCardDetails?.company_bank_account_name,
    selectedBankDepositCard?.value,
  ]);

  useEffect(() => {
    setValue('client', defaultClient);
  }, [defaultClient, setValue]);

  useEffect(() => {
    setValue('company_bank_account', defaultCompanyBankAccount);
  }, [defaultCompanyBankAccount, setValue]);

  useEffect(() => {
    if (selectedClient?.value) {
      const credentials = {
        clientId: selectedClient.value,
        dispatch,
      };
      dispatch(getClientDetails(credentials));
    }
  }, [dispatch, selectedClient]);

  useEffect(() => {
    if (selectedCompanyBank?.value) {
      const credentials = {
        companyBankId: selectedCompanyBank?.value,
      };
      dispatch(getCompanyBankDetails(credentials));
    }
  }, [selectedCompanyBank?.value, dispatch]);

  useEffect(() => {
    if (selectedBankDepositCard?.value) {
      const credentials = {
        bankDepositCardId: selectedBankDepositCard?.value,
      };
      dispatch(getBankDepositCardDetails(credentials));
    }
  }, [selectedBankDepositCard?.value, dispatch]);

  const onSubmit = handleSubmit((data) => {
    const formData = {
      ...data,
      company_bank_account: data.company_bank_account?.value || null,
      client: data.client?.value || null,
      bank_deposit_card: data.bank_deposit_card?.value || null,
    };
    const createCredentials = {
      state: formData,
      dispatch,
      navigate,
    };
    dispatch(createDeposit(createCredentials));
  });

  const handleTextFieldChangeCompanyBank = (value) => {
    setSearchValueCompanyBank(value);
  };

  const handleTextFieldChangeClient = (value) => {
    setSearchValueClient(value);
  };

  const handleTextFiledChangeDepositCard = (value) => {
    setSearchTextDepositCard(value);
  };

  const handleSelection = (type) => {
    setValue('depositType', type);
    setValue('bank_deposit_card', {});
  };

  const handleDateChange = (e) => {
    const dateValue = e.target.value;
    setValue('date', dateValue);
  };

  return (
    <Grid container sx={{ mt: 4 }}>
      <Grid item xs={12} lg={8}>
        <Card sx={{ p: 4 }}>
          <FormProvider methods={methods} onSubmit={onSubmit}>
            <Stack direction="row" justifyContent="space-between" alignItems="center" pb={3}>
              <Typography variant="h6">Add Details</Typography>
              <TextField
                name="date"
                label="Date"
                size="small"
                type="date"
                {...register('date')}
                InputLabelProps={{ shrink: true }}
                onChange={handleDateChange}
                required
              />
            </Stack>
            <Stack direction="row" spacing={2} sx={{ mb: 3 }}>
              {constantValues?.map((type) => (
                <Button
                  key={type?.value}
                  variant="contained"
                  sx={{
                    backgroundColor: selectedType === type?.value ? '#933E88' : '#f3f3f3',
                    color: selectedType === type?.value ? 'white' : 'black',
                    minWidth: 200,
                    borderRadius: '6px',
                    py: 1,
                    '&:hover': {
                      backgroundColor: selectedType === type?.value ? '#933E88' : '#e0e0e0',
                    },
                  }}
                  onClick={() => handleSelection(type?.value)}
                >
                  {type?.label}
                </Button>
              ))}
            </Stack>
            {selectedType === 333 && (
              <Grid lg={12}>
                <Box sx={{ flex: 1, mb: 2 }}>
                  <OspSelectAutocomplete
                    name="bank_deposit_card"
                    control={control}
                    label="Bank Deposit Card"
                    options={optionDepositCards}
                    handleTextFieldChange={handleTextFiledChangeDepositCard}
                    required={selectedType === 333}
                  />
                </Box>
              </Grid>
            )}
            <Grid lg={12}>
              <Stack direction="row" spacing={2} mb={3} alignItems="flex-start">
                {/* Client Autocomplete */}
                <Box sx={{ flex: 1 }}>
                  <OspSelectAutocomplete
                    name="client"
                    control={control}
                    label="Client"
                    options={optionClients}
                    handleTextFieldChange={handleTextFieldChangeClient}
                    disabled={selectedBankDepositCard?.value}
                    required
                  />
                  {selectedClient?.value && clientDetails?.ledger_balance && (
                    <Box
                      sx={{
                        mt: 1,
                        px: 1,
                        py: 0.2,
                        backgroundColor: '#A7009414',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <Typography variant="body2" sx={{ color: '#118D57' }}>
                        BAL
                      </Typography>
                      <Typography variant="body2" sx={{ ml: 1, color: '#006C9C' }}>
                        SR
                      </Typography>
                      <Typography variant="body1" sx={{ ml: 1 }}>
                        {clientDetails?.ledger_balance === null
                          ? '0'
                          : clientDetails?.ledger_balance || ''}
                      </Typography>
                    </Box>
                  )}
                </Box>

                {/* Company Bank Account Autocomplete */}
                <Box sx={{ flex: 1 }}>
                  <OspSelectAutocomplete
                    name="company_bank_account"
                    control={control}
                    label="Company Bank Account"
                    options={optionCompanyBank}
                    handleTextFieldChange={handleTextFieldChangeCompanyBank}
                    disabled={selectedBankDepositCard?.value}
                    required
                  />
                  {selectedCompanyBank?.value && bankDetails?.ledger_balance && (
                    <Box
                      sx={{
                        mt: 1,
                        px: 1,
                        py: 0.2,
                        backgroundColor: '#A7009414',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <Typography variant="body2" sx={{ color: '#118D57' }}>
                        BAL
                      </Typography>
                      <Typography variant="body2" sx={{ ml: 1, color: '#006C9C' }}>
                        SR
                      </Typography>
                      <Typography variant="body1" sx={{ ml: 1 }}>
                        {bankDetails.ledger_balance === null ? '0' : bankDetails.ledger_balance}
                      </Typography>
                    </Box>
                  )}
                </Box>
              </Stack>
            </Grid>
            <Stack width="50%" mb={3}>
              <RHFTextField
                name="amount"
                label="Amount"
                type="number"
                required
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Typography variant="body2" color="textSecondary">
                        SR
                      </Typography>
                    </InputAdornment>
                  ),
                }}
              />
            </Stack>
            <Stack width="50%" mb={3}>
              <RHFTextField name="note" label="Note" multiline rows={3} />
            </Stack>

            <Divider sx={{ mt: 5, borderStyle: 'dotted', mb: 2 }} />

            <Stack direction="row" justifyContent="flex-end" spacing={2}>
              <LoadingButton
                type="submit"
                loading={loading}
                variant="contained"
                sx={{
                  backgroundColor: '#C050B3',
                  color: 'white',
                  '&:hover': { backgroundColor: '#A0409B', color: 'white' },
                }}
              >
                Create
              </LoadingButton>
              <Link to={paths.dashboard.deposit}>
                <Button variant="outlined" sx={{ color: 'black' }}>
                  Cancel
                </Button>
              </Link>
            </Stack>
          </FormProvider>
        </Card>
      </Grid>
    </Grid>
  );
}

export default CreateDepositForm;
