import { useMemo } from 'react';

import { useGetConstant } from 'src/hooks/useHandleSessions';

function useConvertChoice(moduleName, choiceType) {
  const constantValues = JSON.parse(useGetConstant());
  // Define the module data inside the hook
  const modules = {
    'v1.operations': constantValues['v1.operations'],
  };

  return useMemo(() => {
    const module = modules[moduleName];
    if (!module || !module[choiceType]) {
      return { options: [] };
    }

    const options = Object.entries(module[choiceType]).map(([label, value]) => ({
      label: label
        ?.toLowerCase()
        ?.split('_')
        ?.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        ?.join(' '),
      value,
    }));

    return options;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [moduleName, choiceType]);
}

export default useConvertChoice;
