import PropTypes from 'prop-types';

import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { Stack, CardMedia, Typography } from '@mui/material';


// ----------------------------------------------------------------------

export default function TableEmpty({ notFound, sx,img,title,caption }) {
  return (
    <TableRow>
      {notFound ? (
        <TableCell colSpan={12}>
          <Stack spacing={1} py={10}>
              <CardMedia component="img" src={img}  alt="" sx={{ width: 50, height: 50, margin: 'auto' }} />
              <Typography variant="caption" fontWeight={700} textAlign="center">
               {title}
              </Typography>
              <Typography variant="caption" textAlign="center">{`${caption}`}</Typography>
          </Stack>
        </TableCell>
      ) : (
        <TableCell colSpan={12} sx={{ p: 0 }} />
      )}
    </TableRow>
  );
}

TableEmpty.propTypes = {
  notFound: PropTypes.bool,
  sx: PropTypes.object,
  img : PropTypes.any,
  title:  PropTypes.any,
  caption :  PropTypes.any
};
