import { createSlice } from '@reduxjs/toolkit';

import { covertToJSON } from '../../utils/stateToJson';
import {
  createCountry,
  getAllCountry,
  deleteCountry,
  getCountryDetails,
  updateCountryDetails,
} from '../api/country';

export const countrySlice = createSlice({
  name: 'country',
  initialState: {
    loading: false,
    error: {},
    // staff
    country: [],
    staffDropList: [],
    CountryDetails: {},
  },
  reducers: {
    clearStaffError: (state) => {
      state.error = true;
    },
  },
  extraReducers: {
    // create staff details
    [createCountry.pending]: (state) => {
      state.loading = true;
    },
    [createCountry.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.country;
      const newCountrysList = jsonState?.countries;

      const modifiedCountryList = {
        ...jsonState,
        countries: Array.isArray(newCountrysList)
          ? [action.payload, ...newCountrysList]
          : [action.payload],
      };
      state.loading = false;
      state.CountryDetails = action.payload;
      state.country = modifiedCountryList;
      state.error = {};
    },
    [createCountry.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // get all country
    [getAllCountry.pending]: (state) => {
      state.loading = true;
    },
    [getAllCountry.fulfilled]: (state, action) => {      
      state.loading = false;
      state.country = action.payload;
      state.error = {};
    },
    [getAllCountry.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // get single staff details
    [getCountryDetails.pending]: (state) => {
      state.loading = true;
    },
    [getCountryDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.CountryDetails = action.payload;
      state.error = {};
    },
    [getCountryDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    // update staff details
    [updateCountryDetails.pending]: (state) => {
      state.loading = true;
    },
    // [updateCountryDetails.fulfilled]: (state, action) => {
    //   state.loading = false;
    //   state.CountryDetails = action.payload;
    //   state.error = {};
    // },
    [updateCountryDetails.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.country;
      const modifiedCountryList = {
        ...jsonState,
        countries: jsonState.countries?.map((i) =>
          i._id === action.payload._id ? action?.payload : i
        ),
      };

      state.loading = false;
      // state.country = modifiedCountryList;
      state.CountryDetails = action.payload;
      state.country = modifiedCountryList;
      state.error = {};
    },
    // [updateCountryDetails.fulfilled]: (state, action) => {
    //   console.log(action.payload)
    //   const jsonState = covertToJSON(state)?.country;
    //   console.log(jsonState)
    //   const modifiedCountryList = {
    //     ...jsonState,
    //     countries: jsonState.countries?.map((i) => (i._id === action.payload._id ? action?.payload : i)),
    //     // list: Array.isArray(jsonState?.list) ? [...jsonState?.list, action.payload] : [action.payload],
    //   };
    //   state.loading = false;
    //   state.CountryDetails = action.payload;
    //   state.partnerType = modifiedCountryList;
    //   state.error = {};
    // },
    [updateCountryDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [deleteCountry.pending]: (state) => {
      state.loading = true;
    },
    [deleteCountry.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.country;
      const modifiedCountryList = {
        ...jsonState,
        countries: jsonState.countries?.filter((country) => country._id !== action.payload),
      };
      state.loading = false;
      state.country = modifiedCountryList;
      state.error = {};
    },
    [deleteCountry.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
  },
});
export const { clearStaffError } = countrySlice.actions;

export default countrySlice.reducer;
