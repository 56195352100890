import React from 'react';

import { Container } from '@mui/material';

import { MetaHelmet } from 'src/layouts/Helmet/Helmet';

import { useSettingsContext } from 'src/components/settings';

import DepositCardPage from './depositPage';

function AllDeposits() {
  const settings = useSettingsContext();
  return (
    <>
      <MetaHelmet title="Deposit" />
      <Container maxWidth={settings.themeStretch ? false : 'xl'}>
        <DepositCardPage />
      </Container>
    </>
  );
}

export default AllDeposits;
