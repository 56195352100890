// @mui
import { Container } from '@mui/material';

import { MetaHelmet } from 'src/layouts/Helmet/Helmet';

import { useSettingsContext } from 'src/components/settings';

// ----------------------------------------------------------------------

export default function DashboardAdminApp() {
  const settings = useSettingsContext();

  return (
    <>
      <MetaHelmet title="Dashboard" />
      <Container maxWidth={settings.themeStretch ? false : 'xl'} sx={{ mt: 4 }}>
        Dashboard
        {/* {!dashLoading ? <DashboardContent dashboardData={dashboardData} /> : <DashboardLoading />} */}
      </Container>
    </>
  );
}
