import { createSlice } from '@reduxjs/toolkit';

import { covertToJSON } from '../../utils/stateToJson';
// import { covertToJSON } from '../../utils/stateToJson';
import {
  //   createClient,
  createCompanyBank,
  //   deleteClient,
  getAllCompanyBanks,
  getCompanyBankDetails,
  //   getClientDetails,
  updateCompanyBankDetails,
  getAllCompanyBanksDropdown,
} from '../api/companyBank';

export const clientSlice = createSlice({
  name: 'companyBank',
  initialState: {
    loading: false,
    error: {},
    companyBanks: {},
    companyBankDropdownList: [],
    bankDetails: {},
  },
  reducers: {
    clearStaffError: (state) => {
      state.error = true;
    },

    clearCompanyBankDetails: (state) => {
      state.bankDetails = true;
    },
  },
  extraReducers: {
    [createCompanyBank.pending]: (state) => {
      state.loading = true;
    },
    [createCompanyBank.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.companyBanks;
      const newCompanyBankList = jsonState?.results;

      const modifiedClientList = {
        ...jsonState,
        results: Array.isArray(newCompanyBankList)
          ? [...newCompanyBankList, action.payload]
          : [action.payload],
      };
      state.loading = false;
      state.companyBanks = modifiedClientList;
      state.error = {};
    },
    [createCompanyBank.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [getAllCompanyBanks.pending]: (state) => {
      state.loading = true;
    },
    [getAllCompanyBanks.fulfilled]: (state, action) => {
      state.loading = false;
      state.companyBanks = action.payload;
      state.error = {};
    },
    [getAllCompanyBanks.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // company bank dropdown list

    [getAllCompanyBanksDropdown.pending]: (state) => {
      state.loading = true;
    },
    [getAllCompanyBanksDropdown.fulfilled]: (state, action) => {
      state.loading = false;
      state.companyBankDropdownList = action.payload;
      state.error = {};
    },
    [getAllCompanyBanksDropdown.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [getCompanyBankDetails.pending]: (state) => {
      state.loading = true;
    },
    [getCompanyBankDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.bankDetails = action.payload;
      state.error = {};
    },
    [getCompanyBankDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [updateCompanyBankDetails.pending]: (state) => {
      state.loading = true;
    },
    [updateCompanyBankDetails.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.companyBanks;
      const modifiedcompanyBankList = {
        ...jsonState,
        results: jsonState.results?.map((i) => (i.id === action.payload.id ? action?.payload : i)),
      };

      state.loading = false;
      state.companyBanks = modifiedcompanyBankList;
      state.error = {};
    },
    [updateCompanyBankDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    //   [deleteClient.pending]: (state) => {
    //     state.loading = true;
    //   },
    //   [deleteClient.fulfilled]: (state, action) => {
    //     const jsonState = covertToJSON(state)?.clients;
    //     const modifiedClientList = {
    //       ...jsonState,
    //       results: jsonState.results?.filter((mod) => mod?.id !== action.payload),
    //     };
    //     state.loading = false;
    //     state.clients = modifiedClientList;
    //     state.error = {};
    //   },
    //   [deleteClient.rejected]: (state, action) => {
    //     state.loading = false;
    //     state.error = action.error;
    //   },
  },
});
export const { clearStaffError, clearCompanyBankDetails } = clientSlice.actions;

export default clientSlice.reducer;
