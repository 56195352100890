import React from 'react';

import { Box } from '@mui/material';

import SettingsForm from 'src/sections/admin/settings';

function SettingsPage() {
  return (
    <Box sx={{ backgroundColor: '#F8F8F8', minHeight: '90vh' }} pt={8}>
      <SettingsForm />
    </Box>
  );
}

export default SettingsPage;
