import React from 'react';
import { useLocation } from 'react-router';

import { Container } from '@mui/material';

import { MetaHelmet } from 'src/layouts/Helmet/Helmet';

import { useSettingsContext } from 'src/components/settings';

function AllAccounts() {
  const settings = useSettingsContext();
  const location = useLocation();

  const getValueFromSearch = (searchString, parameterName) => {
    const searchParams = new URLSearchParams(searchString);
    return searchParams.get(parameterName);
  };

  const searchValue = getValueFromSearch(location.search, 'search');

  return (
    <>
      <MetaHelmet title="Account" />
      <Container maxWidth={settings.themeStretch ? false : 'xl'}>
        {searchValue === 'ledger-head' && 'Ledger Head Page'}
        {searchValue === 'ledger' && 'Ledger Page'}
        {searchValue === 'ledger-entry' && 'Ledger Entry Page'}
        {searchValue === 'contra' && 'Contra Page'}
        {searchValue === 'balance-sheet' && 'Balance Sheet Page'}
      </Container>
    </>
  );
}

export default AllAccounts;
