import { createSlice } from '@reduxjs/toolkit';

import {
  logoutApi,
  verifyOtpApi,
  adminLoginApi,
  getUserProfile,
  resetPasswordApi,
  updateUserProfile,
  forgotPasswordApi,
} from '../api/auth';

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    passwordLoading: false,
    forgottLoading: false,
    loading: false,
    otpLoading: false,
    loginLoading: false,
    error: {},
    user: {},
    loginDetails: {},
    sessionDetails: {},
    otpDetails: {},
  },
  reducers: {
    clearError: (state) => {
      state.error = {};
    },
  },
  extraReducers: {
    /// admin login
    [adminLoginApi.pending]: (state) => {
      state.loginLoading = true;
    },
    [adminLoginApi.fulfilled]: (state, action) => {
      state.loginLoading = false;
      state.loginDetails = action.payload;
      state.error = {};
    },
    [adminLoginApi.rejected]: (state, action) => {
      state.loginLoading = false;
      state.error = action.error;
    },

    // profile
    [getUserProfile.pending]: (state) => {
      state.loading = true;
    },
    [getUserProfile.fulfilled]: (state, action) => {
      state.loading = false;
      state.user = action.payload;
      state.error = {};
    },
    [getUserProfile.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    // update profile
    [updateUserProfile.pending]: (state) => {
      state.loading = true;
    },
    [updateUserProfile.fulfilled]: (state, action) => {
      state.loading = false;
      state.user = action.payload;
      state.error = {};
    },
    [updateUserProfile.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [forgotPasswordApi.pending]: (state) => {
      state.loading = true;
    },
    [forgotPasswordApi.fulfilled]: (state, action) => {
      state.loading = false;
      state.sessionDetails = action.payload;
      state.error = {};
    },
    [forgotPasswordApi.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [verifyOtpApi.pending]: (state) => {
      state.otpLoading = true;
    },
    [verifyOtpApi.fulfilled]: (state, action) => {
      state.otpLoading = false;
      state.otpDetails = action.payload;
      state.error = {};
    },
    [verifyOtpApi.rejected]: (state, action) => {
      state.otpLoading = false;
      state.error = action.error;
    },

    [resetPasswordApi.pending]: (state) => {
      state.loading = true;
    },
    [resetPasswordApi.fulfilled]: (state, action) => {
      state.loading = false;
      state.otpDetails = action.payload;
      state.error = {};
    },
    [resetPasswordApi.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // logout
    [logoutApi.fulfilled]: (state) => {
      state.user = {};
      state.error = {};
    },
    [logoutApi.rejected]: (state, action) => {
      state.error = action.error;
    },
  },
});
export const { clearError } = authSlice.actions;

export default authSlice.reducer;
