import { createSlice } from '@reduxjs/toolkit';

import { covertToJSON } from 'src/utils/stateToJson';

import {
  createBankDepositCard,
  updateBankDepositCard,
  getAllBankDepositCards,
  getBankDepositCardDetails,
} from '../api/bankDepositCard';

export const depositCardSlice = createSlice({
  name: 'bankDepositCard',
  initialState: {
    loading: false,
    error: {},
    depositCards: {},
    depositCardDetails: {},
  },
  reducers: {
    clearStaffError: (state) => {
      state.error = true;
    },

    clearDepositCardDetails: (state) => {
      state.depositCardDetails = {};
    },
  },
  extraReducers: {
    [createBankDepositCard.pending]: (state) => {
      state.loading = true;
    },
    [createBankDepositCard.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.depositCards;
      const newList = jsonState?.results;

      const modifiedList = {
        ...jsonState,
        results: Array.isArray(newList) ? [...newList, action.payload] : [action.payload],
      };
      state.loading = false;
      state.depositCards = modifiedList;
      state.error = {};
    },
    [createBankDepositCard.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [getAllBankDepositCards.pending]: (state) => {
      state.loading = true;
    },
    [getAllBankDepositCards.fulfilled]: (state, action) => {
      state.loading = false;
      state.depositCards = action.payload;
      state.error = {};
    },
    [getAllBankDepositCards.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [getBankDepositCardDetails.pending]: (state) => {
      state.loading = true;
    },
    [getBankDepositCardDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.depositCardDetails = action.payload;
      state.error = {};
    },
    [getBankDepositCardDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [updateBankDepositCard.pending]: (state) => {
      state.loading = true;
    },
    [updateBankDepositCard.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.depositCards;
      const modifiedList = {
        ...jsonState,
        results: jsonState.results?.map((i) => (i.id === action.payload.id ? action?.payload : i)),
      };
      state.loading = false;
      state.depositCards = modifiedList;
      state.error = {};
    },
    [updateBankDepositCard.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    //   [deleteClient.pending]: (state) => {
    //     state.loading = true;
    //   },
    //   [deleteClient.fulfilled]: (state, action) => {
    //     const jsonState = covertToJSON(state)?.clients;
    //     const modifiedClientList = {
    //       ...jsonState,
    //       results: jsonState.results?.filter((mod) => mod?.id !== action.payload),
    //     };
    //     state.loading = false;
    //     state.clients = modifiedClientList;
    //     state.error = {};
    //   },
    //   [deleteClient.rejected]: (state, action) => {
    //     state.loading = false;
    //     state.error = action.error;
    //   },
  },
});
export const { clearStaffError, clearDepositCardDetails } = depositCardSlice.actions;

export default depositCardSlice.reducer;
