import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
// import { useNavigate } from 'react-router';
import { useMemo, useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';

import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';

import { getSettingsDetails, updateSettingsDetails } from 'src/server/api/settings';

import FormProvider, { RHFTextField } from 'src/components/hook-form';
import OspSelectAutocomplete from 'src/components/hook-form/rhf-OspSelectAutocomplete';

// ----------------------------------------------------------------------

export default function SettingsForm() {
  const dispatch = useDispatch();
  // const navigate = useNavigate();

  const { settingsDetails, loading } = useSelector((state) => ({
    settingsDetails: state.settings.settingsDetails,
    loading: state.settings.loading,
  }));

  // const [searchClientLedgerHead, setSearchClientLedgerHead] = useState('');
  // const [searchBankLedgerHead, setSearchBankLedgerHead] = useState('');
  // const [searchCashLedgerHead, setSearchCashLedgerHead] = useState('');
  // const [searchExpenseLedgerHead, setSearchExpenseLedgerHead] = useState('');
  // const [searchCashInHand, setSearchCashInHand] = useState('');

  const settingSchema = Yup.object().shape({
    default_SAR_to_AED_conversion_rate: Yup.number(),
    default_SAR_to_USD_conversion_rate: Yup.number(),
    default_client_ledger_head: Yup.object(),
    default_bank_ledger_head: Yup.object(),
    default_cash_ledger_head: Yup.object(),
    default_expense_ledger_head: Yup.object(),
    default_cash_in_hand_ledger: Yup.object(),
  });

  const defaultValues = useMemo(
    () => ({
      default_SAR_to_AED_conversion_rate: settingsDetails?.default_SAR_to_AED_conversion_rate || 0,
      default_SAR_to_USD_conversion_rate: settingsDetails?.default_SAR_to_USD_conversion_rate || 0,
      default_client_ledger_head: settingsDetails?.default_client_ledger_head || {},
      default_bank_ledger_head: settingsDetails?.default_bank_ledger_head || {},
      default_cash_ledger_head: settingsDetails?.default_cash_ledger_head || {},
      default_expense_ledger_head: settingsDetails?.default_expense_ledger_head || {},
      default_cash_in_hand_ledger: settingsDetails?.default_cash_in_hand_ledger || {},
    }),
    [settingsDetails]
  );

  const methods = useForm({
    resolver: yupResolver(settingSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    control,
    formState: { isSubmitting },
  } = methods;

  useEffect(() => {
    if (settingsDetails) {
      reset(defaultValues);
    }
  }, [settingsDetails, defaultValues, reset]);

  useEffect(() => {
    const credentials = {
      dispatch,
    };
    dispatch(getSettingsDetails(credentials));
  }, [dispatch]);

  const onSubmit = handleSubmit(async (data) => {
    const credentials = {
      state: {
        ...data,
        default_client_ledger_head: data?.default_client_ledger_head?.value || null,
        default_bank_ledger_head: data?.default_bank_ledger_head?.value || null,
        default_cash_ledger_head: data?.default_cash_ledger_head?.value || null,
        default_expense_ledger_head: data?.default_expense_ledger_head?.value || null,
        default_cash_in_hand_ledger: data?.default_cash_in_hand_ledger?.value || null,
      },
      dispatch,
    };
    dispatch(updateSettingsDetails(credentials));
  });
  //   <>
  //     {mdUp && (
  //       <Grid md={4}>
  //         <Typography variant="h6" sx={{ mb: 0.5 }}>
  //           Exam
  //         </Typography>
  //         <Typography variant="body2" sx={{ color: 'text.secondary' }}>
  //           Exam Details
  //         </Typography>
  //       </Grid>
  //     )}

  //     <Grid xs={12} md={8}>
  //       <Card>
  //         {!mdUp && <CardHeader title="Details" />}

  //         <Stack direction="column" spacing={3} sx={{ p: 3 }}>
  //           <Stack spacing={1.5} width="100%">
  //             <RHFTextField
  //               InputLabelProps={{ shrink: true }}
  //               name="exam_introduction"
  //               label="Exam Introduction"
  //               multiline={4}
  //             />
  //           </Stack>
  //           <Stack spacing={1.5}>
  //             <RHFTextField
  //               InputLabelProps={{ shrink: true }}
  //               name="question_limit"
  //               label="Questions per page"
  //             />
  //           </Stack>
  //         </Stack>
  //       </Card>
  //     </Grid>
  //   </>
  // );

  const renderActions = (
    <Stack direction="row" justifyContent="end" mt={10}>
      <LoadingButton
        type="submit"
        variant="contained"
        sx={{
          backgroundColor: '#C050B3',
          color: 'white',
          ':hover': { backgroundColor: '#C050B3', color: 'white' },
        }}
        loading={isSubmitting || loading}
      >
        Save Changes
      </LoadingButton>
    </Stack>
  );

  const optionClientLedgerHead = [{ name: 'All', id: '' }].map((result) => ({
    label: result?.name,
    value: result?.id,
  }));

  // const handleTextFieldChangeClientLedgerHead = (value) => {
  //   setSearchClientLedgerHead(value);
  // };

  const optionBankLedgerHead = [{ name: 'All', id: '' }].map((result) => ({
    label: result?.name,
    value: result?.id,
  }));

  // const handleTextFieldChangeBankLedgerHead = (value) => {
  //   setSearchBankLedgerHead(value);
  // };

  const optionCashLedgerHead = [{ name: 'All', id: '' }].map((result) => ({
    label: result?.name,
    value: result?.id,
  }));

  // const handleTextFieldChangeCashLedgerHead = (value) => {
  //   setSearchCashLedgerHead(value);
  // };

  const optionExpenseLedgerHead = [{ name: 'All', id: '' }].map((result) => ({
    label: result?.name,
    value: result?.id,
  }));

  // const handleTextFieldChangeExpenseLedgerHead = (value) => {
  //   setSearchExpenseLedgerHead(value);
  // };

  const optionCashInHand = [{ name: 'All', id: '' }].map((result) => ({
    label: result?.name,
    value: result?.id,
  }));

  // const handleTextFieldChangeCashInHand = (value) => {
  //   setSearchCashInHand(value);
  // };

  const renderData = (
    <Stack>
      <Typography variant="subtitle2" pb={2}>
        Default Data
      </Typography>
      <Stack direction="row" spacing={2}>
        <RHFTextField
          name="default_SAR_to_AED_conversion_rate"
          label="SAR to AED Conversion Rate"
          type="number"
          inputProps={{
            step: '0.001',
          }}
          onInput={(e) => {
            const { value } = e.target;
            if (!/^\d+(\.\d{0,3})?$/.test(value)) {
              e.target.value = value.slice(0, -1);
            }
          }}
          // error={!!useShowError(errors, 'default_SAR_to_AED_conversion_rate')}
          // helperText={useShowError(errors, 'default_SAR_to_AED_conversion_rate')}
        />
        <RHFTextField
          name="default_SAR_to_USD_conversion_rate"
          label="SAR to USD Conversion Rate"
          type="number"
          inputProps={{
            step: '0.001',
          }}
          onInput={(e) => {
            const { value } = e.target;
            if (!/^\d+(\.\d{0,3})?$/.test(value)) {
              e.target.value = value.slice(0, -1);
            }
          }}
          // error={!!useShowError(errors, 'default_SAR_to_USD_conversion_rate')}
          // helperText={useShowError(errors, 'default_SAR_to_USD_conversion_rate')}
        />
      </Stack>
      <Typography variant="subtitle2" pb={2} pt={5}>
        Default Ledger Head
      </Typography>
      <Stack direction="row" spacing={2} mb={2}>
        <OspSelectAutocomplete
          name="default_client_ledger_head"
          control={control}
          options={optionClientLedgerHead}
          label="Client Leadger Head"
          // handleTextFieldChange={handleTextFieldChangeClientLedgerHead}
        />
        <OspSelectAutocomplete
          name="default_bank_ledger_head"
          control={control}
          options={optionBankLedgerHead}
          label="Bank Ledger Head"
          // handleTextFieldChange={handleTextFieldChangeBankLedgerHead}
        />
      </Stack>
      <Stack direction="row" spacing={2}>
        <OspSelectAutocomplete
          name="default_cash_ledger_head"
          control={control}
          options={optionCashLedgerHead}
          label="Cash Ledger Head"
          // handleTextFieldChange={handleTextFieldChangeCashLedgerHead}
        />
        <OspSelectAutocomplete
          name="default_expense_ledger_head"
          control={control}
          options={optionExpenseLedgerHead}
          label="Expense Ledger Head"
          // handleTextFieldChange={handleTextFieldChangeExpenseLedgerHead}
        />
      </Stack>
      <Typography variant="subtitle2" pb={2} pt={5}>
        Default Ledger
      </Typography>
      <Stack width="50%">
        <OspSelectAutocomplete
          name="default_cash_in_hand_ledger"
          control={control}
          options={optionCashInHand}
          label="Cash in Hand Ledger"
          // handleTextFieldChange={handleTextFieldChangeCashInHand}
        />
      </Stack>
    </Stack>
  );

  return (
    <FormProvider methods={methods} onSubmit={onSubmit}>
      <Stack direction="row" justifyContent="center">
        <Card sx={{ p: 3, minWidth: 550 }}>
          <Typography variant="h4" textAlign="center" pb={5}>
            Default configuration
          </Typography>
          {renderData}
          {renderActions}
        </Card>
      </Stack>
    </FormProvider>
  );
}
