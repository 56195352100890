import React from 'react';
import { format } from 'date-fns';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';

export default function DepositModal({ open, onClose, data }) {
  const { date, client_name, bank_deposit_card_name, company_bank_account_name, note, amount } =
    data || {};

  const formattedDate = date ? format(new Date(date), 'd MMMM, yyyy') : 'Invalid Date';

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      sx={{
        '& .MuiPaper-root': {
          width: '450px', // Set fixed width
          height: 'auto', // Adjust height automatically
          borderRadius: '16px',
          paddingBottom: 2,
        },
      }}
    >
      <DialogTitle
        sx={{
          textAlign: 'center',
          fontWeight: 'bold',
          fontSize: '1.25rem',
          borderBottom: '1px solid #ddd',
        }}
      >
        Deposit Details
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 20,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {/* Amount */}
        <Typography
          align="center"
          sx={{
            fontWeight: 'bold',
            fontSize: '2rem',
            color: '#212B36',
            marginBottom: 0.5,
            mt: 2,
          }}
        >
          <Typography component="span" sx={{ color: 'gray', fontWeight: 'bold', fontSize: '2rem' }}>
            SR
          </Typography>{' '}
          {amount}
        </Typography>
        {/* Date */}
        <Typography
          align="center"
          sx={{
            fontSize: '0.875rem',
            color: 'text.secondary',
            marginBottom: 1,
          }}
        >
          {formattedDate}
        </Typography>
        <Divider sx={{ gridColumn: '1 / -1', borderStyle: 'dotted', marginY: 0.5, mb: 2 }} />

        {/* Details Section */}
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: '1fr 2fr',
            gap: 0.75,
          }}
        >
          {/* Bank Deposit Card */}
          <Typography variant="body2" sx={{ textAlign: 'left' }}>
            Bank Deposit Card
          </Typography>
          <Typography
            variant="body2"
            sx={{ textAlign: 'right', color: '#637381', fontWeight: 600 }}
          >
            {bank_deposit_card_name}
          </Typography>
          <Divider sx={{ gridColumn: '1 / -1', borderStyle: 'dotted', marginY: 0.5 }} />

          {/* Client */}
          <Typography variant="body2" sx={{ textAlign: 'left' }}>
            Client
          </Typography>
          <Typography
            variant="body2"
            sx={{ textAlign: 'right', color: '#637381', fontWeight: 600 }}
          >
            {client_name}
          </Typography>
          <Divider sx={{ gridColumn: '1 / -1', borderStyle: 'dotted', marginY: 0.5 }} />

          {/* Company Bank */}
          <Typography variant="body2" sx={{ textAlign: 'left' }}>
            Company Bank
          </Typography>
          <Typography
            variant="body2"
            sx={{ textAlign: 'right', color: '#637381', fontWeight: 600, marginBottom: 0 }}
          >
            {company_bank_account_name}
          </Typography>
          <Divider sx={{ gridColumn: '1 / -1', borderStyle: 'dotted', marginY: 0.5 }} />

          {/* Remarks */}
          <Typography variant="body2" sx={{ textAlign: 'left' }}>
            Remarks
          </Typography>
          <Typography
            variant="body2"
            sx={{ textAlign: 'right', color: '#637381', fontWeight: 600, marginBottom: 0 }}
          >
            {note}
          </Typography>
          <Divider sx={{ gridColumn: '1 / -1', borderStyle: 'dotted', marginY: 0.5 }} />
        </Box>
      </DialogContent>
    </Dialog>
  );
}

DepositModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  data: PropTypes.shape({
    date: PropTypes.string,
    client_name: PropTypes.string,
    bank_deposit_card_name: PropTypes.string,
    company_bank_account_name: PropTypes.string,
    note: PropTypes.string,
    amount: PropTypes.string,
  }),
};
