import { createSlice } from '@reduxjs/toolkit';

import { covertToJSON } from '../../utils/stateToJson';
import { createDeposit, deleteDeposit, getAllDeposits } from '../api/deposit';

export const depositCardSlice = createSlice({
  name: 'deposit',
  initialState: {
    loading: false,
    error: {},
    deposits: {},
    depositDetails: {},
  },
  reducers: {
    clearStaffError: (state) => {
      state.error = true;
    },
  },
  extraReducers: {
    [createDeposit.pending]: (state) => {
      state.loading = true;
    },
    [createDeposit.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.deposits;
      const newDepostList = jsonState?.results;

      const modifiedClientList = {
        ...jsonState,
        results: Array.isArray(newDepostList)
          ? [...newDepostList, action.payload]
          : [action.payload],
      };
      state.loading = false;
      state.combanyBanks = modifiedClientList;
      state.error = {};
    },
    [createDeposit.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [getAllDeposits.pending]: (state) => {
      state.loading = true;
    },
    [getAllDeposits.fulfilled]: (state, action) => {
      state.loading = false;
      state.deposits = action.payload;
      state.error = {};
    },
    [getAllDeposits.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    //   [getCombanyBankDetails.pending]: (state) => {
    //     state.loading = true;
    //   },
    //   [getCombanyBankDetails.fulfilled]: (state, action) => {
    //     state.loading = false;
    //     state.bankDetails = action.payload;
    //     state.error = {};
    //   },
    //   [getCombanyBankDetails.rejected]: (state, action) => {
    //     state.loading = false;
    //     state.error = action.error;
    //   },

    //   [updateCombanyBankDetails.pending]: (state) => {
    //     state.loading = true;
    //   },
    //   [updateCombanyBankDetails.fulfilled]: (state, action) => {
    //     const jsonState = covertToJSON(state)?.combanyBanks;
    //     const modifiedcombanyBankList = {
    //       ...jsonState,
    //       results: jsonState.results?.map((i) => (i.id === action.payload.id ? action?.payload : i)),
    //     };

    //     state.loading = false;
    //     state.combanyBanks = modifiedcombanyBankList;
    //     state.error = {};
    //   },
    //   [updateCombanyBankDetails.rejected]: (state, action) => {
    //     state.loading = false;
    //     state.error = action.error;
    //   },

    [deleteDeposit.pending]: (state) => {
      state.loading = true;
    },
    [deleteDeposit.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.deposits;
      const modifiedDepositList = {
        ...jsonState,
        results: jsonState.results?.filter((mod) => mod?.id !== action.payload),
      };
      state.loading = false;
      state.deposits = modifiedDepositList;
      state.error = {};
    },
    [deleteDeposit.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
  },
});
export const { clearStaffError } = depositCardSlice.actions;

export default depositCardSlice.reducer;
