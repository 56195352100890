import React from 'react';

import { Container } from '@mui/material';

import { paths } from 'src/routes/routes/paths';

import { MetaHelmet } from 'src/layouts/Helmet/Helmet';

import { useSettingsContext } from 'src/components/settings';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs/custom-breadcrumbs';

import CreateDepositForm from 'src/sections/Deposit/depositForm';

function CreateDeposit() {
  const settings = useSettingsContext();
  return (
    <>
      <MetaHelmet title="Deposit " />
      <Container maxWidth={settings.themeStretch ? false : 'xl'}>
        <CustomBreadcrumbs
          heading="Add new deposit "
          links={[
            {
              name: 'Dashboard',
              href: paths.dashboard.root,
            },
            {
              name: 'Deposit',
              href: paths.dashboard.deposit,
            },
            {
              name: 'Add Deposit',
            },
          ]}
          sx={{
            mb: { xs: 3, md: 5 },
          }}
        />
        <CreateDepositForm />
      </Container>
    </>
  );
}

export default CreateDeposit;
