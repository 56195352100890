import { createAsyncThunk } from '@reduxjs/toolkit';

import { paths } from 'src/routes/routes/paths';

import { useSetRole, useSetToken, useSetSessionKey } from 'src/hooks/useHandleSessions';

import { get, put, post } from './http';
import { activeSnack } from '../store/common';

export const adminLoginApi = createAsyncThunk(
  'auth/login',
  async ({ state, dispatch, navigate }) => {
    try {
      const response = await post('/users/login/', state);
      if (response?.token) {
        useSetToken(response?.token);
        useSetRole(response?.role);
        navigate(paths.dashboard.client, { replace: true });
        dispatch(activeSnack({ type: 'success', message: response?.response }));
        return response;
      }
      return dispatch(
        activeSnack({ type: 'error', message: response?.error_message || 'something went wrong' })
      );
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.error_message }));
      throw error?.response?.data?.error_message;
    }
  }
);

export const forgotPasswordApi = createAsyncThunk(
  'auth/forgot',
  async ({ state, dispatch, handleOtp, setSeconds }) => {
    try {
      const response = await post('/users/forget-password-email/', state);
      if (response) {
        if (handleOtp) {
          handleOtp();
        }
        if (setSeconds) {
          setSeconds(60);
        }
        useSetSessionKey(response?.session_key);
        dispatch(activeSnack({ type: 'success', message: `OTP Sent to ${state?.email}` }));
        return { ...response, email: state.email };
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const verifyOtpApi = createAsyncThunk(
  'auth/otp-verification',
  async ({ state, dispatch, navigate }) => {
    try {
      const response = await post('/users/verify-forget-password/', state);
      if (response) {
        if (navigate) {
          navigate(paths.public.reset_password, { state: { email: response?.email } });
        }
        dispatch(activeSnack({ type: 'success', message: response?.message }));
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.error_message }));
      throw error?.response?.data?.error_message;
    }
  }
);

export const resetPasswordApi = createAsyncThunk(
  'auth/rest-password',
  async ({ state, dispatch, navigate }) => {
    try {
      const response = await post('/users/change-password/', state);
      if (response) {
        if (navigate) {
          navigate(paths.public.login);
        }
        dispatch(activeSnack({ type: 'success', message: response?.message }));
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const logoutApi = createAsyncThunk('auth/logout', async ({ dispatch, navigate }) => {
  try {
    const response = await get('/logout');
    if (response) {
      sessionStorage.clear();
      dispatch(activeSnack({ type: 'success', message: 'logged out successfully' }));
      navigate(paths.public.landing, { replace: true });
      return response;
    }
    return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
  } catch (error) {
    sessionStorage.clear();
    navigate(paths.public.login, { replace: true });
    dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
    throw error?.response?.data?.message;
  }
});

export const getUserProfile = createAsyncThunk('user/profile', async ({ enqueueSnackbar }) => {
  try {
    const response = await get(`/user-account`);
    if (response?.id) {
      return response;
    }
    return enqueueSnackbar('something went wrong', { variant: 'error' });
  } catch (error) {
    enqueueSnackbar(error?.response?.data?.message, { variant: 'error' });
    throw error?.response?.data?.message;
  }
});

export const updateUserProfile = createAsyncThunk(
  'user/profile/update',
  async ({ enqueueSnackbar, state }) => {
    try {
      const response = await put(`/user/update`, state);
      if (response?._id) {
        enqueueSnackbar('Profile Updated Successfully');
        return response;
      }
      return enqueueSnackbar('something went wrong', { variant: 'error' });
    } catch (error) {
      enqueueSnackbar(error?.response?.data?.message, { variant: 'error' });
      throw error?.response?.data?.message;
    }
  }
);
